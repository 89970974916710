import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as AuthReducer from '@app/store/features/auth/auth.reducer';
import { StateStatus } from '@app/enums/state-status';

export const getAuthState = createFeatureSelector<AuthReducer.State>('auth');

export const getLoggedInStatus = createSelector(
    getAuthState,
    (state) => state?.loggedIn
);

export const getLoggedInUserId = createSelector(
    getAuthState,
    (state) => state?.userId
);

export const loading = createSelector(
    getAuthState,
    (state) => state.status === StateStatus.loading
);

export const forgotPassword = createSelector(
    getAuthState,
    (state) => state.forgotPassword
);