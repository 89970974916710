import { Injectable, inject } from '@angular/core';
import { LoginRequest } from '../models/login-request';
import { ServerRequestMessage } from '@app/enums/server-request';
import { SecurityCodeRequest } from '../models/security-code-request';
import { Observable, map } from 'rxjs';
import { ServerMessageType } from '@app/enums/server-message-type';
import { ServerMsgAccountRequestSecurityCodeResponse } from '@app/models/server-msg';
import { WsService } from '@app/services/ws.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly _ws = inject(WsService);

  loginUsernamePassword(username: string, password: string) {
    const data: LoginRequest = {
      type: ServerRequestMessage.Connect,
      Str1: username.trim(),
      Str2: password,
    }
    console.log("🟢", data)
    this._ws.sendData(data);
  }

  loginWithSecurityCode(email: string, securityCode: string) {
    const data: LoginRequest = {
      type: ServerRequestMessage.Connect,
      Str1: email.trim(),
      Str4: securityCode.trim(),
    }
    this._ws.sendData(data);
  }

  logout(): void {
    this._ws.sendData({ type: ServerRequestMessage.Disconnect });
  }

  // Forgot Password Action
  requestSecurityCode(email: string): void {
    const data: SecurityCodeRequest = {
      type: ServerRequestMessage.AccountRequestSecurityCode,
      Str1: email.trim()
    }
    this._ws.sendData(data);
  }


  getAccountRequestSecurityCodeResponse(): Observable<ServerMsgAccountRequestSecurityCodeResponse> {
    return this._ws.getServerMsg<ServerMsgAccountRequestSecurityCodeResponse>(ServerMessageType.AccountRequestSecurityCodeResponse)
  }

}
