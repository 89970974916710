import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as Config from '@app/store/features/config/config.reducer';


export const getState = createFeatureSelector<Config.State>('config');

export const getAppReadyToUse = createSelector(
    getState,
    (state) => state.appReadyToUse
);

export const getGameInitialized = createSelector(
    getState,
    (state) => state.gameInitialized
);

export const getGlobalSettings = createSelector(
    getState,
    (state) => state.globalSettings
);

export const getSkinSettings = createSelector(
    getState,
    (state) => state.skinSettings
);

export const getJackPotsInfo = createSelector(
    getState,
    (state) => state.jackPotsInfo
);

export const getJackPotsInfoById = (jackPotInfoId: number) => createSelector(
    getState,
    (state) => state.jackPotsInfo.find(jackPotInfo => jackPotInfo.id === jackPotInfoId)
);

export const getCasinosInfo = createSelector(
    getState,
    (state) => state.casinosInfo
);

export const getDomainSettings = createSelector(
    getState,
    (state) => state.domainSettings
);


export const getDefaultAssetsLoaded = createSelector(
    getState,
    (state) => state.assets.default.loaded
);