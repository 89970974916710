import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { getAuthState } from '../../../store/features/auth/auth.selectors';
import { map, tap } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  const router = inject(Router);

  return store.pipe(
    select(getAuthState),
    map(authState => {

      if (authState.loggedIn) {
        return router.createUrlTree(['/lobby']);
      } else {
        return true
      }
    })
  )
};
