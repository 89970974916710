
import { Injectable } from '@angular/core';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ServerResponse } from '@app/enums/server-response';
import { Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { LobbyService } from '../../../features/lobby/services/lobby.service';

import * as LobbyActions from '../lobby/lobby.actions';
import * as TournamentSummariesActions from './tournament-summaries.actions';
import * as TournamentSummariesSelectors from './tournament-summaries.selector';

import { WsService } from '@app/services/ws.service';
import { TournamentSummary, TournamentSummaryResponse } from '@app/models/tournament-summary';
import { TournamentInformationResponse } from '@app/models/tournament-information';
import { timer } from 'rxjs';

@Injectable()
export class TournamentsSummariesEffects {
    constructor(
        private _ws: WsService,
        private readonly _store: Store,
        private readonly lobbyService: LobbyService,
        private readonly _actions: Actions,

    ) { }

    // updateActiveNav$ = createEffect(
    //     () =>
    //         this.actions$.pipe(
    //             ofType(LobbyActions.updateActiveNav),
    //             filter(({ activeNav }) => activeNav === LobbyNav.Tournaments),
    //             tap(() => this.lobbyService.getTournamentSummaries()),
    //             map(() => TournamentSummariesActions.updateLoader({ loader: true }))
    //         ));


    updateTournamentSummaries$ = createEffect(() =>
        this._ws.getDataResponse<TournamentSummaryResponse[]>(ServerResponse.TournamentsSummaries)
            .pipe(
                map(tournamentSummariesResponse => {
                    const tournamentSummaries: TournamentSummary[] = tournamentSummariesResponse.map(tournamentSummaryResponse => {
                        return this.lobbyService.updateTournamentSummary(tournamentSummaryResponse)
                    })
                    console.log("tournamentSummaries", tournamentSummaries)
                    return TournamentSummariesActions.updateAll({ tournamentSummaries })
                })
            ));


    updateTournamentSummary$ = createEffect(() =>
        this._ws.getDataResponse<TournamentSummaryResponse>(ServerResponse.TournamentSummary)
            .pipe(
                map(tournamentSummaryResponse => {
                    const tournamentSummary = this.lobbyService.updateTournamentSummary(tournamentSummaryResponse)

                    console.log("tournamentSummary", tournamentSummary)

                    return TournamentSummariesActions.updateOne({ id: tournamentSummary.id, tournamentSummary })
                }))
    );

    updateTournamentInformationResponse$ = createEffect(() =>
        this._ws.getDataResponse<TournamentInformationResponse>(ServerResponse.TournamentInformation)
            .pipe(
                map(tournamentInformationResponse => {
                    return TournamentSummariesActions.updateOne({
                        id: tournamentInformationResponse.idTournament,
                        tournamentSummary: {
                            nbPlayerRegistered: tournamentInformationResponse.nbPlayerRegistered,
                            nbRebuyUsed: tournamentInformationResponse.nbRebuyUsed,
                            nbAddonUsed: tournamentInformationResponse.nbAddonUsed,
                            totalPrizePool: tournamentInformationResponse.totalPrizePool
                        }
                    })
                }))
    );



    // tournamentCountDown$ = createEffect(
    //     () =>
    //         this._actions.pipe(
    //             ofType(TournamentSummariesActions.startTournamentCountDown),
    //             switchMap(() => timer(0, 1000)),
    //             //   takeUntil(this._actions.pipe(ofType(TournamentSummariesActions.stopTournamentCountDown))),
    //             tap(() => console.log("timer")),
    //             switchMap(iteration => {
    //                 return this._store.pipe(
    //                     select(TournamentSummariesSelectors.selectAll),
    //                     take(1),
    //                     map(tournamentSummaries => {
    //                         return tournamentSummaries.map(tournamentSummary => {
    //                             let tournamentSummaryUpdate = { ...tournamentSummary }

    //                             tournamentSummaryUpdate.game.scheduleDemo = `${iteration}`
    //                             return tournamentSummaryUpdate
    //                         })
    //                     })
    //                 )
    //             }),
    //             map(tournamentSummaries => TournamentSummariesActions.updateTournamentCountDown())
    //         )
    // )


} 