import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Ticket } from '@app/models/ticket';
import * as TicketsActions from '@app/store/features/tickets/tickets.actions';

export interface State extends EntityState<Ticket> {

}

export const adapter: EntityAdapter<Ticket> = createEntityAdapter<Ticket>({
    selectId: (model: Ticket) => model.id,
});

export const initialState: State = adapter.getInitialState({

});

export const reducer = createReducer(initialState,
    on(TicketsActions.addMany, (state, { tickets }) => {
        return adapter.addMany(tickets, state);
    }),
);
