import { Observable, Observer } from 'rxjs';

export class Base64Encode {

    private fileReader: FileReader;
    private base64Encoded: string;
    observer: Observer<string>;

    constructor() {
        this.fileReader = new FileReader();
        this.fileReader.onload = () => {
            this.base64Encoded = this.fileReader.result!.toString();
            const onlyBase64 = this.base64Encoded.replace(/^data:image\/[a-z]+;base64,/, '');
            this.observer.next(onlyBase64);
            this.observer.complete();
        };
    }

    encodeFile(file: File): Observable<string> {
        return new Observable(observer => {
            this.observer = observer;
            this.fileReader.readAsDataURL(file);
        });
    }
}
