import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Gift } from '@app/models/gift';
import * as AuthActions from '@app/store/features/auth/auth.actions';
import * as GiftsActions from '@app/store/features/gifts/gifts.actions';

export interface State extends EntityState<Gift> {

}

export const adapter: EntityAdapter<Gift> = createEntityAdapter<Gift>({
    selectId: (model: Gift) => model.id,
});

export const initialState: State = adapter.getInitialState({

});

export const reducer = createReducer(initialState,

    on(GiftsActions.setAll, (state, { gifts }) => {
        return adapter.setAll(gifts, state);
    }),

    on(AuthActions.onLogout, (state) => {
        return adapter.removeAll({ ...state });
    }),
);
