import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyInfo } from '../models/currency-info';

@Pipe({ name: 'gcy', standalone: true })
export class GameCurrencyPipe implements PipeTransform {
    transform(value: number, currency: CurrencyInfo | undefined | null, getRawValue: boolean = false): string {
        if (!currency) { return value.toString(); }
        if (getRawValue) { return (value / currency.multiplier).toString() }
        let retVal = (value / currency.multiplier).toLocaleString('en-US', { minimumFractionDigits: currency ? currency.nbDecimalVisible : 0, maximumFractionDigits: currency ? currency.nbDecimalVisible : 0 });

        if (!currency.symbol) { return retVal; }

        retVal = currency.symbolAfter ? `${retVal}${currency.symbol}` : `${currency.symbol}${retVal}`;

        return retVal;
    }
}
