import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as mainReducers from '@app/store/features/main/main.reducers';

export const selectMainState = createFeatureSelector<mainReducers.State>('main');
export const selectLobbyState = createSelector(selectMainState, (state) => state.lobby);


export const selectStatsPlayerOnline = createSelector(
    selectLobbyState,
    (state) => state?.statsPlayerOnline
);

export const selectActiveNav = createSelector(
    selectLobbyState,
    (state) => state?.activeNav
);

export const selectActivePreview = createSelector(
    selectLobbyState,
    (state) => state?.activePreview
);

export const selectActivePreviewNav = createSelector(
    selectLobbyState,
    (state) => state?.activePreviewNav
);

export const selectFilters = createSelector(
    selectLobbyState,
    (state) => state?.filters
);

export const selectError = createSelector(
    selectLobbyState,
    (state) => state?.error
);