import { State, createFeatureSelector, createSelector } from "@ngrx/store";
import * as mainReducers from '@app/store/features/main/main.reducers';
import * as TournamentsReducer from './tournaments.reducer';

export const selectMainState = createFeatureSelector<mainReducers.State>('main');
export const selectTournamentsState = createSelector(selectMainState, (state) => state.tournaments);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = TournamentsReducer.adapter.getSelectors(selectTournamentsState);

export const selectEntityById = (id: number) => createSelector(
    selectEntities,
    (entities) => entities[id]
);