
import { Point } from '../models/point';
import { Texture, Sprite, Container, TextStyle, Text } from 'pixi.js';

export class ChatBubble {
    private messageTextPosition: Point = { x: 96, y: 47 };

    private messageTextStyle: TextStyle = new TextStyle({
        fontFamily: 'Saira Semi Condensed',
        fontWeight: '400',
        fontSize: 18,
        fill: '#000000',
        wordWrap: true,
        wordWrapWidth: 130,
        align: 'center'
    });

    public container: Container;
    private bubbleSprite: Sprite;
    private messegeText: Text;
    private messageToDisplay: string;
    private charIndex = 0;
    private timerRef: NodeJS.Timer;

    constructor(textures: Texture, position: Point, data: { message: string, }) {
        this.container = new Container();
        this.container.position.set(position.x, position.y);

        this.bubbleSprite = new Sprite(textures);
        this.container.addChild(this.bubbleSprite);

        this.messegeText = new Text('', this.messageTextStyle);
        this.messegeText.anchor.set(0.5);
        this.messegeText.position.set(this.messageTextPosition.x, this.messageTextPosition.y);
        this.container.addChild(this.messegeText);

        this.messageToDisplay = data.message;

        this.timerRef = setInterval(() => this.doTextAnimation(), 25);
    }

    private doTextAnimation() {
        if (this.charIndex >= this.messageToDisplay.length) {
            clearInterval(this.timerRef);
            setTimeout(() => this.selfDistruct(), 2000);
            return;
        }
        this.charIndex++;
        this.messegeText.text = this.messageToDisplay.substring(0, this.charIndex);
    }

    private selfDistruct() {
        this.container.parent.removeChild(this.container);
    }
}
