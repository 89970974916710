import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-init-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './init-loader.component.html',
  styleUrls: ['./init-loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'app-init-loader' }
})
export class InitLoaderComponent {

}
