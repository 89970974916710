import { createAction, props } from '@ngrx/store';
import { LobbyPreviewNav } from '../../../features/lobby/enums/lobby-preview-nav';
import { StatsPlayerOnline } from '../../../features/lobby/models/stats-player-online';
import { LobbyFilters } from './lobby.reducer';
import { LobbyFilterTableType } from '../../../features/lobby/enums/lobby-filter-table-type';
import { TableSummary } from '@app/models/table-summary';
import { TournamentSummary } from '@app/models/tournament-summary';
import { SitNGoSummary } from '@app/models/sit-n-go-summary';
import { SpinNGoSummary } from '@app/models/spin-n-go-summary';
import { CurrencyInfo } from '@app/models/currency-info';

export const updateStatsPlayerOnline = createAction('[Lobby] Update Stats Player Online', props<{ statsPlayerOnline: StatsPlayerOnline }>());
export const updateActiveNav = createAction('[Lobby] Update Active Nav', props<{ activeNav: LobbyFilterTableType }>());
export const updateActivePreview = createAction('[Lobby] Update Active Preview', props<{ tableId?: number, tournamentId?: number }>());
export const updateActivePreviewNav = createAction('[Lobby] Update Active Preview Nav', props<{ activeNav: LobbyPreviewNav }>());
export const updateFiltersChange = createAction('[Lobby] Update Filters', props<{ filters: LobbyFilters }>());
export const updateError = createAction('[Lobby] Update Error', props<{ error: string | undefined }>());


export const onSelectTable = createAction('[Lobby] Select Table', props<{ tableSummary: TableSummary }>());
export const onSelectTournament = createAction('[Lobby] Select Tournament', props<{ tournamentSummary: TournamentSummary }>());
export const onSelectSitNGo = createAction('[Lobby] Select SitNGo', props<{ sitNGoSummary: SitNGoSummary }>());
export const onSelectSpinNGo = createAction('[Lobby] Select SpinNGo', props<{ spinNGoSummary: SpinNGoSummary }>());

export const onFiltersChange = createAction('[Lobby] on Filters Change', props<{ filters: Partial<LobbyFilters> }>());
export const onChangeCurrency = createAction('[Lobby] on Change Currency', props<{ currency: CurrencyInfo }>());


