import { TournamentSummary } from '@app/models/tournament-summary';
import { createAction, props } from '@ngrx/store';

export const updateLoader = createAction('[Tournament Summaries] Update Loader', props<{ loader: boolean }>());
export const updateAll = createAction('[Tournament Summaries] Update Tournament Summaries', props<{ tournamentSummaries: TournamentSummary[] }>());
export const updateOne = createAction('[Tournament Summary] Update Tournament Summary', props<{ id: number, tournamentSummary: Partial<TournamentSummary> }>());

// export const startTournamentCountDown = createAction('[Tournament Summary] startTournamentCountDown');
// export const stopTournamentCountDown = createAction('[Tournament Summary] stopTournamentCountDown');
// export const updateTournamentCountDown = createAction('[Tournament Summary] updateTournamentCountDown');
