import { createAction, props } from '@ngrx/store';
import { Tournament } from './tournaments.reducer';

export const upsertOne = createAction('[Tournaments] Upsert Tournament', props<{ tournament: Tournament }>());
export const removeOne = createAction('[Tournaments] Remove Tournament', props<{ tournamentId: number }>());
export const updateOne = createAction('[Tournaments] Update Tournament', props<{ tournamentId: number; tournament: Partial<Tournament> }>());


export const viewTournament = createAction('[Tournaments] View Tournament', props<{ tournamentId: number; }>());
export const registerTournament = createAction('[Tournaments] Register Tournament', props<{ tournamentId: number; register: boolean }>());
