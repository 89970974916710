import { Injectable, inject } from '@angular/core';
import { WsService } from './ws.service';
import { ServerRequestMessage } from '@app/enums/server-request';

@Injectable({
  providedIn: 'root'
})
export class GiftService {

  private readonly _ws = inject(WsService)



  unlock(giftId: number) {
    this._ws.sendData({
      type: ServerRequestMessage.GiftUnlock,
      value: giftId
    });
  }
}
