import { createReducer, on } from '@ngrx/store';
import * as CashierActions from '@app/store/features/cashier/cashier.actions';
import { Gateway } from '@app/features/cashier/models/gateway';
import { Transaction } from '@app/features/cashier/models/transaction';

export interface State {
    depositGateways: Gateway[];
    withdrawalGateways: Gateway[];
    transactionsHistory: Transaction[];
}

export const initialState: State = {
    depositGateways: [],
    withdrawalGateways: [],
    transactionsHistory: [],
};

export const reducer = createReducer(
    initialState,
    on(CashierActions.setGateways, (state, { depositGateways, withdrawalGateways }) => ({ ...state, depositGateways, withdrawalGateways })),
    on(CashierActions.setTransactionsHistory, (state, { transactions }) => ({ ...state, transactionsHistory: transactions })),

);