import { Gateway } from '@app/features/cashier/models/gateway';
import { Transaction } from '@app/features/cashier/models/transaction';
import { createAction, props } from '@ngrx/store';

export const setGateways = createAction('[Cashier] setGateways', props<{ depositGateways: Gateway[]; withdrawalGateways: Gateway[]; }>());
export const setTransactionsHistory = createAction('[Cashier] setTransactionsHistory', props<{ transactions: Transaction[]; }>());


export const transferMoneyToPlayer = createAction('[Cashier] transferMoneyToPlayer', props<{ username: string; amount: number; currency: number; note?: string }>());
export const getTransactionsHistory = createAction('[Cashier] getTransactionsHistory');
export const cancelWithdrawal = createAction('[Cashier] cancelWithdrawal');


export const requestDeposit = createAction('[Cashier] requestDeposit', props<{ gatewayName: string, amount: number, currencyId: number, promoCode?: string }>());
export const requestWithdrawal = createAction('[Cashier] requestWithdrawal', props<{ gatewayName: string, amount: number, currencyId: number, extraInfo: string }>());


export const uploadVerificationDocuments = createAction('[Cashier] uploadVerificationDocuments', props<{ fileGovId?: File; fileUtilityBill?: File; filePan?: File; }>());


export const requestPhoneVerificationCodeSms = createAction('[Cashier] requestPhoneVerificationCodeSms');

export const submitPhoneCode = createAction('[Cashier] submitPhoneCode', props<{ code: string; }>());
