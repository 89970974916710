import { State, createFeatureSelector, createSelector } from "@ngrx/store";
import * as PlayerBalanceReducer from './player-balance.reducer';
import * as mainReducers from '@app/store/features/main/main.reducers';

export const selectMainState = createFeatureSelector<mainReducers.State>('main');
export const selectPlayerBalanceState = createSelector(selectMainState, (state) => state.playerBalance);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = PlayerBalanceReducer.adapter.getSelectors(selectPlayerBalanceState);

export const selectEntityById = (id: number) => createSelector(
    selectEntities,
    (entities) => entities[id]
);
