import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { Inject, inject } from "@angular/core";

export class DialogBase {
  protected dialogRef = inject(DialogRef);

  close(): void {
    this.dialogRef.close();
  }
}
