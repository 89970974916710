import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import * as LobbyChatActions from './lobby-chat.actions';
import { LobbyChatMessage } from '@app/features/lobby-chat/models/lobby-chat-message';

export interface State extends EntityState<LobbyChatMessage> {
    loader: boolean;

}

export const adapter: EntityAdapter<LobbyChatMessage> = createEntityAdapter<LobbyChatMessage>({
    selectId: (model: LobbyChatMessage) => model.id,
    sortComparer: dateSort,
});

export const initialState: State = adapter.getInitialState({
    loader: true,
});

export const reducer = createReducer(initialState,
    on(LobbyChatActions.setAll, (state, { messages }) => {
        return adapter.setAll(messages, { ...state, loader: false });
    }),

    on(LobbyChatActions.updateOne, (state, { id, message }) => {
        return adapter.updateOne({ id, changes: { ...message } }, { ...state, loader: false });
    }),

    on(LobbyChatActions.upsertOne, (state, { message }) => {
        return adapter.upsertOne(message, { ...state, loader: false });
    }),


);


function dateSort(propA: LobbyChatMessage, propB: LobbyChatMessage): number {
    return propA.timestamp > propB.timestamp ? 1 : -1;
}