
import { Injectable, inject } from '@angular/core';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ServerResponse } from '@app/enums/server-response';
import { Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { LobbyService } from '../../../features/lobby/services/lobby.service';


import * as ConfigSelectors from '@app/store/features/config/config.selectors';

import { WsService } from '@app/services/ws.service';
import { TableSummary, TableSummaryDTO } from '@app/models/table-summary';
import { LiveLobbyRing } from '@app/models/live-lobby-ring';
import * as PlayerBalanceSelectors from '@app/store/features/player-balance/player-balance.selectors';
import { ServerMsgPlayerBalance } from '@app/models/server-msg';
import { ServerMessageType } from '@app/enums/server-message-type';
import { PlayerBalance } from '@app/models/player-balance';
import { AccountType } from '@app/models/account-type';
import { Ticket, TicketDTO } from '@app/models/ticket';
import * as TicketseActions from '@app/store/features/tickets/tickets.actions';

@Injectable()
export class TicketsEffects {
    private readonly _ws = inject(WsService);
    private readonly _actions$ = inject(Actions);
    private readonly _store = inject(Store);


    tickets$ = createEffect(() => this._ws.getDataResponse<TicketDTO[]>(ServerResponse.Tickets)
        .pipe(
            tap((tickets) => console.log('tickets', tickets)),
            map(tickets => {
                return TicketseActions.addMany({ tickets: tickets.map(el => el as Ticket) })
            })
        ));


} 