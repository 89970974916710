import { State, createFeatureSelector, createSelector } from "@ngrx/store";
import * as CurrenciesReducer from './currencies.reducer';
import * as mainReducers from '@app/store/features/main/main.reducers';

export const selectMainState = createFeatureSelector<mainReducers.State>('main');
export const selectCurrenciesState = createSelector(selectMainState, (state) => state.currencies);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = CurrenciesReducer.adapter.getSelectors(selectCurrenciesState);

export const selectEntityById = (id: number) => createSelector(
    selectEntities,
    (entities) => entities[id]
);

export const selectActive = createSelector(
    selectCurrenciesState,
    (state) => state?.selected
);
