import { Assets, Graphics, Text, Application, Container, Texture, Sprite, Loader, TextStyle, ITextStyle } from 'pixi.js';
import { Point } from '../models/point';
import { CardData } from '../models/card-data';
import { Card } from './card';




export class PlayerCardsController {
    readonly playerZoneWidth = 355
    readonly playerZoneHeight = 295
    readonly playerAvatarCircleSize = 150
    readonly infoBoxWidth = 260
    readonly inRowVisibleCardWidth = 86

    container = new Container();


    cards: Card[] = [];


    constructor(
        // textures: {
        //     playerCardAtlas: Texture,
        //     backCard: Texture,
        //     cardRabbitHunting: Texture
        // },
        // data: {

        // }
    ) {
        if (false) {
            setTimeout(() => {
                this.addCards([
                    // #1
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h9.png'] },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h8.png'] },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h7.png'] },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h6.png'] },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h5.png'] },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h4.png'] },

                    // #2
                    { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h9.png'] },
                    { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h8.png'] },
                    { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h7.png'] },
                    { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h6.png'] },
                    { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h5.png'] },
                    { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-playerCardAtlas').textures['h4.png'] },
                    // { suit: 0, number: 9, name: 'h9', isHidden: false, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },
                    // { suit: 0, number: 9, name: 'h9', isHidden: false, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },

                    // #3
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },
                    // { suit: 0, number: 9, name: 'h9', isHidden: true, isRabbitHunting: undefined, texture: Assets.get('game-backCard') },

                ], false)

            }, 1000);


        }
    }

    update(dt: number) {
        this.cards.forEach(card => {
            card.update(dt);
        });
    }

    addCards(cards: CardData[], isRotated: boolean = false) {
        cards
            .forEach((cardData, index) => {
                const cardIndex = cardData.isHidden ? 0 : this.container.children.length;
                let card = new Card(cardData.texture!, { info: cardData });
                card = this.setCard(card, index, isRotated && cardData.isHidden, cards.length)
                this.cards.push(card);
                this.container.addChildAt(card.container, cardIndex);
                console.log("@ game init cards, data.card", this.cards, cardIndex, this.container.children)

            });
    }

    setCard(card: Card, index: number, rotateCard?: boolean, totalNumberOfCards?: number) {

        if (!rotateCard) {
            const cardsPosition: Point = this.cardPosition(index, totalNumberOfCards!);

            card.container.position.set(cardsPosition.x, cardsPosition.y)
            card.container.scale.set(this.inRowVisibleCardWidth / card.cardSprite.texture.width)


            return card
        }



        const setX = this.playerZoneWidth / 2 - this.infoBoxWidth / 2 - this.playerZoneWidth * 0.02
        const setY = this.playerAvatarCircleSize / 2 + this.playerZoneHeight * 0.07

        switch (index) {
            case 0:
                card.container.position.set(setX, setY);
                card.container.rotation = -0.275 - (index * 0.275)
                card.container.scale.set(0.8);
                break;
            case 1:
                card.container.position.set(setX - 20, setY + 20);
                card.container.rotation = -0.275 - (index * 0.275)
                card.container.scale.set(0.75);

                break;

            case 2:
                card.container.position.set(setX - 35, setY + 45);
                card.container.rotation = -0.275 - (index * 0.275)
                card.container.scale.set(0.7);

                break;

            case 3:
                card.container.position.set(setX - 40, setY + 75);
                card.container.rotation = -0.275 - (index * 0.275)
                card.container.scale.set(0.65);
                break;

            case 4:
                card.container.position.set(setX - 40, setY + 105);
                card.container.rotation = -0.275 - (index * 0.275)
                card.container.scale.set(0.6);

                break;

            case 5:
                card.container.position.set(setX - 30, setY + 135);
                card.container.rotation = -0.275 - (index * 0.275)
                card.container.scale.set(0.55);
                break;

            case 6:
                card.container.position.set(setX - 15, setY + 160);
                card.container.rotation = -0.275 - (index * 0.275)
                card.container.scale.set(0.5);
                break;
        }


        return card
    }


    private cardPosition(cardIndex: number, numberOfCards: number): Point {



        let offset: number = 0;
        let cardsWidth;

        while (!cardsWidth || cardsWidth > this.playerZoneWidth) {
            if (offset > 0) {
                offset -= 0.01
            } else {
                offset = 0.5 // default offset
            }

            cardsWidth = this.inRowVisibleCardWidth + (numberOfCards - 1) * (this.inRowVisibleCardWidth * (offset)) // Width of all cards in the row
        }

        return { x: this.inRowVisibleCardWidth * offset * cardIndex + (this.playerZoneWidth - cardsWidth) / 2, y: this.playerAvatarCircleSize / 2 }
        // const start = 610 / 2 - ((CONST.cardWidth * this.SHOWABLE_CARD_SCALE * numberOfCards) / 2 - (CONST.cardWidth * this.SHOWABLE_CARD_SCALE * (numberOfCards - 1) * OFFSET) / 2)

        // const cardPositionOffset = CONST.cardWidth * this.SHOWABLE_CARD_SCALE - CONST.cardWidth * this.SHOWABLE_CARD_SCALE * OFFSET;

        const cardsPositions: Point[] = [];

        for (let i = 0; i < numberOfCards; i++) {
            const position: Point = { x: this.inRowVisibleCardWidth * offset * i, y: 70 };
            cardsPositions.push(position);
        }
        //  return cardsPositions;
    }




    removeAllCards() {
        this.container.removeChildren();
        this.cards = [];
    }

    selectCards(cardsToSelect: CardData[] = []) {

        if (cardsToSelect.length === 0) { return }

        this.cards.forEach(card => {
            if (!card || !card.info) { return; }
            if (cardsToSelect.findIndex(selectCard => selectCard.suit === card.info.suit && selectCard.number === card.info.number) === -1) {
                card.grayscaleCard();
            } else {
                card.ungrayscaleCard();
            }
        });
    }

    grayscaleCards() {
        this.cards.forEach(card => {
            card.grayscaleCard()
        })
    }

}
