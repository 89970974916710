// @localhost
export const environment = {
    pingPongTime: 60000,

    domainname: 'dev.createpoker.app',
    //   domainname: 'instantplay.oshipoker.com',
    // domainname: 'instantplay.aionpoker.com',
    // domainname: 'instantplay.winall.store',

    dataSource: 'https://data.createpoker.app/app/game',
    gameDevMode: false,

};
