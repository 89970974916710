import { createReducer, on } from '@ngrx/store';
import * as ConfigActions from '@app/store/features/config/config.actions';
import { SkinSettings } from '@app/models/skin-settings';
import { GlobalSettings } from '@app/models/global-settings';
import { JackPotInfo } from '@app/models/jackpot-info';
import { DomainSettings } from '@app/models/domain-settings';

interface LocalConfig {
    assetsLoaded: boolean;
    gameInitialized: boolean;
}

export interface State extends LocalConfig {
    // emoticonsInfo: EmoticonInfo[];

    casinosInfo: any[];
    jackPotsInfo: JackPotInfo[];
    appReadyToUse: boolean; // will be reacived from the server after the login

    globalSettings: GlobalSettings | null; // mostly used for the game
    skinSettings: SkinSettings | null; // (firebase) will be reacived from the server after the login
    domainSettings: DomainSettings | null; // creator configuration for domainname

    assets: {
        default: {
            loaded: number;
        },

        //  later extandable with themes
        // theme: {

        // }
    }
}

export const initialState: State = {
    // emoticonsInfo: [],

    casinosInfo: [],
    jackPotsInfo: [],

    appReadyToUse: false,
    assetsLoaded: true,
    gameInitialized: false,



    globalSettings: null,
    skinSettings: null,
    domainSettings: null,
    assets: {
        default: {
            loaded: 0
        }
    }
};

export const reducer = createReducer(
    initialState,
    on(ConfigActions.updateAppReadyToUse, (state, { appReadyToUse }) => ({ ...state, appReadyToUse })),
    on(ConfigActions.updateGameInitialized, (state, { gameInitialized }) => ({ ...state, gameInitialized })),
    on(ConfigActions.updateGlobalSettings, (state, { globalSettings }) => ({ ...state, globalSettings })),
    on(ConfigActions.updateSkinSettings, (state, { skinSettings }) => ({ ...state, skinSettings })),

    on(ConfigActions.updateCasinosInfo, (state, { casinosInfo }) => ({ ...state, casinosInfo })),
    on(ConfigActions.updateJackPotsInfo, (state, { jackPotsInfo }) => ({ ...state, jackPotsInfo })),

    on(ConfigActions.updateDomainSettings, (state, { domainSettings }) => ({ ...state, domainSettings })),

    on(ConfigActions.onAssetsLoading, (state, { percentage }) => ({ ...state, assets: { default: { loaded: percentage } } })),

);