import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogBase } from '../dialog/dialog-base';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-generic-dialog',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'dialog app-generic-dialog' }
})
export class GenericDialogComponent extends DialogBase {
  title: string | null = null;
  text: string | null = null;
  image: string | null = null;

  dissmissBtn: string | null = null;
  confirmBtn: string | null = null;
  
  linkBtn: {
    text: string,
    href: string
  } | null = null;

  confirm(): void {
    this.dialogRef.close(true)
  }
}
