import { Component, ElementRef, HostListener, OnDestroy, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, catchError, filter, finalize, of, switchMap, tap } from 'rxjs';

import { LobbyFilterLimit } from './features/lobby/enums/lobby-filter-limit';
import { map, toPairs } from 'lodash';
import { LobbyFilterSpeed } from './features/lobby/enums/lobby-filter-speed';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogContainer, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

import * as ConfigActions from '@app/store/features/config/config.actions';
import * as ConfigSelectors from '@app/store/features/config/config.selectors';

import * as AuthSelectors from '@app/store/features/auth/auth.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSnackBar, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { WsService } from './services/ws.service';
import { InitLoaderComponent } from './components/init-loader/init-loader.component';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ButtonComponent } from './components/button/button.component';
import * as AuthActions from '@app/store/features/auth/auth.actions';
import { Assets, Application, Container, Sprite } from 'pixi.js';
import * as GameHorizontal from '@app/features/game/consts/game-horizontal.const';

const angularMaterialModules = [
  MatSlideToggleModule,
  MatExpansionModule,
  MatDialogModule,

  MatSnackBarModule,

  OverlayModule
]
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'app-root' },
  imports: [
    CommonModule,
    RouterModule,
    InitLoaderComponent,
    ...angularMaterialModules
  ]
})
export class AppComponent {

  gameConstData = GameHorizontal

  getGameInitialized$ = this.store.pipe(select(ConfigSelectors.getGameInitialized));
  getDefaultAssetsLoaded$ = this.store.pipe(select(ConfigSelectors.getDefaultAssetsLoaded));

  // count$: Observable<number>;

  wsConnetion = new BehaviorSubject<void>(undefined);

  private readonly _snackBar = inject(MatSnackBar);

  private readonly _overlay = inject(Overlay);

  isOpen = false;


  // if you want to use structural directives, we should use static false (for eg. ngIf, ngFor...)
  @ViewChild('pixiGameContainer', { static: true }) pixiGameContainer: ElementRef
  gameAssets: any;
  constructor(public dialog: MatDialog, private store: Store<{ count: number }>, private _ws: WsService) {
   


    this.testAssets()



    // this._ws.stream.subscribe()
    // this.count$ = store.select('count');


    // this.wsConnetion.pipe(
    //   tap(() => console.log('ws connection')),
    //   // tap(() => {
    //   //   // It means that local storage is supported

    //   //   console.log("typeof Storag", typeof Storage,localStorage.getItem('authCredentials'))

    //   //   if (typeof Storage !== "undefined") {

    //   //     const authCredentials = localStorage.getItem('authCredentials')
    //   //     if (authCredentials) {
    //   //       const { username, password } = JSON.parse(authCredentials)
    //   //       if (username && password) {
    //   //         this.store.dispatch(AuthActions.onLoginWithUsernamePasswordFromCredentials({ username, password }))
    //   //       }

    //   //     }
    //   //   }
    //   // }),
    //   switchMap(() => this._ws.connect().pipe(
    //     catchError(() => {
    //       console.log('WS error')
    //       this.wsConnetion.next()
    //       return of({})
    //     }),
    //     finalize(() => this.wsConnetion.next())
    //   )),
    // ).subscribe()


    //this.store.pipe(select(getCoreState)).subscribe(a => console.log('getCoreState ----', new Date(), (a as any).ws))
    // this.store.pipe(select(getAuthState)).subscribe(a => console.log('getAuthState ----', a))
    //this.store.pipe(select(getStatsPlayerOnline)).subscribe(a => console.log('getStatsPlayerOnline ----', a))

    //   this._ws.login()


    console.log('environment', environment)

    this.store.select(AuthSelectors.getLoggedInStatus)
      .pipe(

    )

    this.getGameInitialized$.subscribe(d => console.log("@aaa", d))

    // this.store.pipe(

    //   select(AuthSelectors.getLoggedInStatus),
    //   filter(loggedIn => loggedIn),
    //   tap(() => {
    //     this.appReadyToUseDialogRef = this._snackBar.openFromComponent(AppReadyToUseComponent, { panelClass: 'app-ready-to-use-snack-bar-panel' })
    //   }),
    //   switchMap(() => this.store.pipe(select(ConfigSelectors.getAppReadyToUse))),
    //   filter(appReadyToUse => appReadyToUse),
    //   tap(() => {
    //     this.appReadyToUseDialogRef.dismiss()
    //   }),
    //   takeUntilDestroyed(),

    // ).subscribe()

    this.store.pipe(select(AuthSelectors.getLoggedInStatus))
      .subscribe((appReadyToUse) => console.log(' +++++++++ getLoggedInStatus', appReadyToUse))

    this.store.pipe(select(ConfigSelectors.getAppReadyToUse))
      .subscribe((appReadyToUse) => console.log(' +++++++++ appp ready to use', appReadyToUse))
  }



  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    // this.appReadyToUseDialogRef = this.dialog.open(AppReadyToUseComponent, {
    //   width: '250px',
    //   enterAnimationDuration,
    //   exitAnimationDuration,
    // });
  }

  startLoader() {
    // const overlayRef = this._overlay.create();
    // const userProfilePortal = new ComponentPortal(UserProfile);
    // overlayRef.attach(userProfilePortal);
  }

  async testAssets() {



    // this.gameAssets = await Assets.loadBundle([
    //   'game',
    //   'game-horizonal'
    // ], (loadingPercentage) => {
    //   console.log("@loading progress", Math.floor(loadingPercentage * 100))
    //   if (loadingPercentage === 1) {
    //     console.log("@loading progress", "done")



    //   }
    // })
    return
    const game = new Application({ width: 1920, height: 1080, backgroundAlpha: 1 });

    const container = new Container();

    // Table
    const table = new Sprite(Assets.get('game-horizonal-table4'))
    table.anchor.set(0.5);
    table.position.set(game.view.width / 2, table.height / 2 + this.gameConstData.tableOffsetTop); // 100 is the offset from the top
    container.addChild(table)

    // Table logo
    const logo = new Sprite(Assets.get('game-logo'))
    logo.anchor.set(0.5);
    logo.scale.set(0.7)
    logo.position.set(game.view.width / 2, logo.height / 2 + (table.height - logo.height) / 2 + this.gameConstData.tableOffsetTop * 0.9); // 100 is the offset from the top
    container.addChild(logo)
    console.log("@pixi", logo.height / 2)
    game.stage.addChild(container);
    this.pixiGameContainer.nativeElement.appendChild(game.view);
    game.view.style!.width = '100%';
  }
}