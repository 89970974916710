import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as mainReducers from '@app/store/features/main/main.reducers';

const selectMainState = createFeatureSelector<mainReducers.State>('main');
export const selectUserState = createSelector(selectMainState, (state) => state.user);

export const selectUserProfile = createSelector(
    selectUserState,
    (state) => state.profile
);

export const selectCheckUsernameExist = createSelector(
    selectUserState,
    (state) => state.checkUsernameExist
);

export const selectCheckEmailExist = createSelector(
    selectUserState,
    (state) => state.checkEmailExist
);
