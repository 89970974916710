
import { Injectable } from '@angular/core';
import { filter, map, tap } from 'rxjs/operators';
import { ServerResponse } from '@app/enums/server-response';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { LobbyService } from '../../../features/lobby/services/lobby.service';
// import { LobbyNav } from '../../enums/lobby-nav';
// import * as LobbyActions from '../actions/lobby.actions';
import * as SitNGoSummariesActions from './sit-n-go-summaries.actions';
import { WsService } from '@app/services/ws.service';
import { SitNGoSummary, SitNGoSummaryDTO } from '@app/models/sit-n-go-summary';

@Injectable()
export class SitNGoSummariesEffects {
    constructor(
        private _ws: WsService,
        private readonly store: Store,
        private readonly lobbyService: LobbyService,
        private actions$: Actions,

    ) { }

    // updateActiveNav$ = createEffect(
    //     () =>
    //         this.actions$.pipe(
    //             ofType(LobbyActions.updateActiveNav),
    //             filter(({ activeNav }) => activeNav === LobbyNav.SitNGo),
    //             tap(() => this.lobbyService.getSitNGoSummaries()),
    //             map(() => SitNGoSummariesActions.updateLoader({ loader: true }))
    //         ));


    updateSitNGoSummaries$ = createEffect(() =>
        this._ws.getDataResponse<SitNGoSummaryDTO[]>(ServerResponse.SitNGoSummaries)
            .pipe(
                map(sitNGoSummariesResponse => {
                    const sitNGoSummaries: SitNGoSummary[] = sitNGoSummariesResponse.map(sitNGoSummaryDTO => {
                        return this.lobbyService.updateSitNGoSummary(sitNGoSummaryDTO)
                    })

                    return SitNGoSummariesActions.updateAll({ sitNGoSummaries })
                })
            ));

} 