import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import * as SpinAndGoSummaryActions from './spin-n-go-summaries.actions';

import * as AuthActions from '@app/store/features/auth/auth.actions';
import { SpinNGoSummary } from '@app/models/spin-n-go-summary';

export interface State extends EntityState<SpinNGoSummary> {
    loader: boolean;
}

export const adapter: EntityAdapter<SpinNGoSummary> = createEntityAdapter<SpinNGoSummary>({
    selectId: (model: SpinNGoSummary) => model.id,
    sortComparer: nameSort,
});

export const initialState: State = adapter.getInitialState({
    loader: false,
});

function nameSort(propA: SpinNGoSummary, propB: SpinNGoSummary): number {
    return propA.name > propB.name ? -1 : 1;
}

export const reducer = createReducer(initialState,
    on(SpinAndGoSummaryActions.updateLoader, (state, { loader }) => {
        return { ...state, loader };
    }),

    on(SpinAndGoSummaryActions.updateAll, (state, { spinNGoSummaries }) => {
        return adapter.setAll(spinNGoSummaries, { ...state, loader: false });
    }),

    on(SpinAndGoSummaryActions.updateOne, (state, { spinNGoSummary }) => {
        return adapter.updateOne({ id: spinNGoSummary.id, changes: { ...spinNGoSummary } }, { ...state });
    }),

    on(AuthActions.onLogout, (state) => {
        return adapter.removeAll({ ...state });
    }),
);


