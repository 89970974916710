import { inject } from '@angular/core';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { map, tap } from 'rxjs';
import * as AuthSelectors from '@app/store/features/auth/auth.selectors';

export const mainGuard: CanActivateFn = (route, state) => {
  const store: Store<any> = inject(Store);
  const router: any = inject(Router);

  return store.pipe(
    select(AuthSelectors.getAuthState),
    map(authState => {
      if (!authState?.loggedIn) {
        return router.createUrlTree(['/auth/login'], { queryParams: { returnUrl: state.url } });
      } else {
        return true
      }
    })
  )
};
