import { Injectable, inject } from '@angular/core';
import { Observable, ReplaySubject, Subject, filter, map, mergeMap, of, shareReplay, switchMap, tap } from 'rxjs';
import { webSocket, WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';
import * as _ from 'lodash';
import { ServerMsg } from '@app/models/server-msg';
import { ServerMessageType } from '@app/enums/server-message-type';
import { ServerResponse } from '@app/enums/server-response';
import { ServerRequestMessage } from '@app/enums/server-request';
import { ConnectionStatus } from '@app/enums/connection-status';

import { Store, select } from '@ngrx/store';

import * as WSActions from '@app/store/features/web-socket/web-socket.actions';

@Injectable({
  providedIn: 'root'
})
export class WsService {

  private readonly _store: Store<any> = inject(Store);

  ws: WebSocketSubject<any>;
  wsConnection: Observable<any>
  //stream = new ReplaySubject<any>()
  stream = new Subject<any>()

  constructor() {
    console.log(">>>>>>>>>>>>>WS Service")
  }

  connect(url: string, skinName: string) {
    console.log("@WS Connect 🌱")
    this.ws = webSocket({
      url, //: 'wss://testgame.theonlypoker.com:14445',
      openObserver: {
        next: () => {
          this._store.dispatch(WSActions.updateConnectionStatus({ status: ConnectionStatus.Connected }))

          console.log("🟢", "Connected");
          this.ws.next({
            type: 3,
            Str1: `Angular`, // Name
            Str2: skinName, // Skin/ReferralName
            Str3: 'v4.0', // Client Version
            Str4: 'TestingBrowser', // OsVersion
            Str5: `${Math.random()}`, // Unique guid to identify the device (can be generated locally) this will let connection overtake existing one.
            Value2: 2 // platform (1 for Windows, 2: HTML, 3:Android, 4:iOS, 7:Mac, 8:Bot)
          })
        }
      },
      closeObserver: {
        next: () => {
          this._store.dispatch(WSActions.updateConnectionStatus({ status: ConnectionStatus.Disconnected }))
          console.log("🔴", "Disconnected")
        }
      },
      closingObserver: {
        next: () => {
          // this._store.dispatch(WSActions.updateConnectionStatus({ status: ConnectionStatus.Disconnected }))
          console.log("🔴", "Closing")
        }
      },
      deserializer: (event) => {
        return this.format(JSON.parse(event.data), 'camelCase')
      },
      serializer: (value) => {
        return JSON.stringify(this.format(value, 'pascalCase'))
      }
    })



    setTimeout(() => {
      //  this.disconnect()
    }, 5000);
    return this.ws
      .asObservable()
      .pipe(
        // map(message => this.format(message, 'camelCase')),
        //  map(message => this.formatValues(message, 'camelCase')),
        tap(message => console.log('🟠', message)),
        tap(message => this.stream.next(message)),

      )



  }

  // login() {
  //   console.log("here")
  //   this.ws.next(this.format({ type: 1, str1: 'igor1', str2: '12345' }, 'pascalCase'))


  //   setTimeout(() => {
  //     const data = { "Type": 100, "Currency": 1, "Filters": { "TableType": 1, "Stakes": null, "Limits": null, "Speeds": null, "NbSeats": null, "HideFull": false, "HideEmpty": false, "ShowPrivate": false, "FreerollOnly": false, "ShowOnlyWithTip": false } }
  //     console.log("🟢", data)
  //     this.ws.next(data)
  //   }, 1000);
  // }



  format(data: any, type: 'camelCase' | 'pascalCase'): any {
    if (_.isArray(data)) {
      return data.map((item: any) => this.format(item, type));
    } else if (_.isObject(data) && data !== null) {
      const convertedObj: any = {};
      _.mapKeys(data, (value: any, key: any) => {
        const newKey = type === 'camelCase' ? _.camelCase(key) : _.upperFirst(_.camelCase(key));
        convertedObj[newKey] = this.format(value, type);
      });
      return convertedObj;
    }

    if (typeof data === 'string') {
      if (data === 'False' || data === 'false') {
        return false;
      } else if (data === 'True' || data === 'true') {
        return true;
      }
    }
    return data;
  }


  sendData(data: any): void {
    this.ws.next(data)
  }

  getDataResponse<T>(serverResponse: ServerResponse): Observable<T> {
    return this.stream
      .pipe(
        filter(data => data.response === serverResponse),
        map(data => data[_.camelCase(serverResponse)] as T)
      )
  }

  getServerMsg<T>(serverMessageType: ServerMessageType): Observable<T> {
    return this.stream
      .pipe(
        // tap(data => console.log('🟢', data)),

        filter(data => data.response === 'ServerMsg'),
        //    tap(data => console.log('1 🟢', data)),

        filter(data => (data.serverMsg as ServerMsg[]).filter(serverMsg => serverMsg.msgType === serverMessageType).length > 0),
        map(data => (data.serverMsg as ServerMsg[]).filter(serverMsg => serverMsg.msgType === serverMessageType)),
        mergeMap(data => data as T[]),
        //   tap(data => console.log('2 🟢', data))
      )
  }

  disconnect(): void {
    this.ws.complete()
  }

  sendPing() {
    this.sendData({
      type: ServerRequestMessage.Ping,
      Value2: new Date().getTime() + Math.random()
    });
  }
}
