import { Injectable } from '@angular/core';
import { ChatMessageAPI, ChatMessagePlayer, LobbyChatMessage } from '../models/lobby-chat-message';
import { DomainSettings } from '@app/models/domain-settings';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LobbyChatAdapterService {

  constructor() { }

  from(data: ChatMessageAPI, domainSettings: DomainSettings): LobbyChatMessage {

    let player: ChatMessagePlayer | undefined = undefined;

    if (data.playerData) {
      player = {
        id: data.playerData.id,
        name: data.playerData.name,
        avatar: data.playerData.avatar ? `${domainSettings.httpUrl}/avatar/${data.playerData.avatar}` : `${environment.dataSource}/assets/${domainSettings.code}/player/avatar.png`,
        rank: data.playerData.rank,
        level: data.playerData.level
      }
    }

    return {
      text: data.text,
      date: new Date(data.date),
      timestamp: new Date(data.date).getTime(),
      id: data.value,
      idReplaced: data.value2,
      isDeleted: data.value3 === 1,
      isAdmin: !data.idPlayer,
      player
    }
  }
}
