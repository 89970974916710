import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import * as TournamenstActions from './tournaments.actions';
import * as AuthActions from '@app/store/features/auth/auth.actions';
import { TournamentSummary } from '@app/models/tournament-summary';
import { TournamentInfo } from '@app/models/tournament-information';
import { Blind } from '@app/models/blind';
import { SitNGoSummary } from '@app/models/sit-n-go-summary';
import { SpinNGoSummary } from '@app/models/spin-n-go-summary';
import { TournamentActiveTable } from '@app/models/tournament-active-table';
import { ErrorCode } from '@app/enums/errors';

export interface Tournament {
    id: number;
    tournamentSummary?: TournamentSummary;
    tournamentInfo?: TournamentInfo;
    blinds?: Blind[];
    sitNGoSummary?: SitNGoSummary;
    spinNGoSummary?: SpinNGoSummary;
    activeTables?: TournamentActiveTable[];
    isRegistered?: boolean;

    errorCode?: ErrorCode;
}

export interface State extends EntityState<Tournament> {

}


export const adapter: EntityAdapter<Tournament> = createEntityAdapter<Tournament>({
    selectId: (model: Tournament) => model.id,
    sortComparer: false,
});

export const initialState: State = adapter.getInitialState({

});


export const reducer = createReducer(initialState,
    on(TournamenstActions.upsertOne, (state, { tournament }) => {
        return adapter.upsertOne({ ...tournament }, { ...state });
    }),

    on(TournamenstActions.removeOne, (state, { tournamentId }) => {
        return adapter.removeOne(tournamentId, { ...state });
    }),

    on(TournamenstActions.updateOne, (state, { tournamentId, tournament }) => {
        console.log('updateOne', tournamentId, tournament)
        return adapter.updateOne({ id: tournamentId, changes: { ...tournament } }, { ...state });
    }),

    on(AuthActions.onLogout, (state) => {
        return adapter.removeAll({ ...state });
    }),
);
