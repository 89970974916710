<div class="app-input-type" [ngSwitch]="type">
    <div *ngSwitchCase="'checkbox'">
        <input [formControl]="control" class="inp-cbx" [attr.id]="id"
            type="checkbox" />
        <label class="cbx" [attr.for]="id">
            <span>
                <svg width="12px" height="10px">
                    <use [attr.xlink:href]="'#icon-'+id"></use>
                </svg>
            </span>
            <span><ng-container *ngTemplateOutlet="contentTpl"></ng-container>
            </span></label>
        <svg class="inline-svg">
            <symbol [attr.id]="'icon-'+id" viewbox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
            </symbol>
        </svg>
    </div>
    <div class="app-input-type-radio" *ngSwitchCase="'radio'">

        <input [formControl]="control" class="inp-cbx" [name]="name"
            [attr.id]="id" type="radio" [value]="value" />
        <label class="cbx" [ngClass]="{'rounded': rounded}" [attr.for]="id">
            <span></span>
            <span><ng-container *ngTemplateOutlet="contentTpl"></ng-container>
            </span></label>
    </div>
    <div class="app-input-type-default" *ngSwitchDefault>
        <img class="app-input-type-default-icon" *ngIf="icon" [src]="icon" alt>
        <span *ngIf="symbol"
            class="app-input-type-default-symbol">{{symbol}}</span>
        <input autocomplete="one-time-code"
            class="app-input-type-default-control"
            [ngClass]="{'icon': icon ,'symbol':symbol}"
            [min]="min" [max]="max" [step]="step"
            [formControl]="control" [type]="type" [placeholder]="placeholder"
            (keyup.enter)="onEnter()">

        <div class="app-input-type-default-error"
            *ngIf="control.touched && errors && errors.length>0">
            <div class="app-input-type-default-error-item"
                *ngFor="let error of errors">{{error.key}}</div>
        </div>
    </div>
</div>

<ng-template #contentTpl><ng-content></ng-content></ng-template>