import { createAction, props } from '@ngrx/store';

export const onLogout = createAction('[Auth] onLogout');


export const loginSuccessful = createAction('[Auth] loginSuccessful', props<{ userId: number }>());
export const loginError = createAction('[Auth] loginError');

export const onOpenLoginPage = createAction('[Auth] onOpenLoginPage');
export const onOpenForgotPasswordPage = createAction('[Auth] onOpenForgotPasswordPage');

export const onRequestSecurityCode = createAction('[Auth] onRequestSecurityCode', props<{ email: string }>());
export const onLoginWithSecurityCode = createAction('[Auth] onLoginWithSecurityCode', props<{ securityCode: string }>());
export const onLoginWithUsernamePassword = createAction('[Auth] onLoginWithUsernamePassword', props<{ username: string, password: string; rememberMe: boolean }>());
export const onLoginWithUsernamePasswordFromCredentials = createAction('[Auth] onLoginWithUsernamePasswordFromCredentials', props<{ username: string, password: string }>());

export const updateForgotPasswordEmail = createAction('[Auth] resetForgotPasswordEmail', props<{ email: string }>());

