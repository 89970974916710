export enum VariantType2 {
    TexasHoldEm = 1,
    TexasHoldEmRock = 2, // (ring only)
    CapTexasHoldEm = 3, // CAP, where CAP means max you can lose in a single hand. (ring only), (not limit only)
    Omaha = 10,
    OmahaHiLo = 11, /* Omaha with hi/lo winners */
    Omaha5 = 12, /* Omaha with 5 cards instead of 4 */
    Omaha5HiLo = 13, /* Omaha with 5 cards instead of 4 with hi/lo winners */
    Omaha6 = 14, /* Omaha with 6 cards instead of 4 */
    TriOmaha = 15, // the combination will be from 3 cards, exactly 2 cards from player's 4 cards and only 1 from the table. so best combination is AAA
    CapOmaha = 16, // Omaha with CAP, where CAP means max you can lose in a single hand, on all bet rounds. (ring only), (pot limit only)
    CapOmaha5 = 22, // Omaha with CAP, where CAP means max you can lose in a single hand, on all bet rounds. (ring only), (pot limit only)
    CapOmaha6 = 23, // Omaha with CAP, where CAP means max you can lose in a single hand, on all bet rounds. (ring only), (pot limit only)
    CapOmaha7 = 27, // Omaha7 with CAP, where CAP means max you can lose in a single hand, on all bet rounds. (ring only), (pot limit only)
    Omaha7 = 24, // Omaha with 7 cards instead of 4
    TripleDraw = 17, // 2/7 triple draw
    Badugi = 18, // Badugi
    Badeucey = 19, // Badeucey = mix of triple draw and badugi
    SevenStud = 20, // player receives 7 cards, 2 face down - private, 4 face up - everyone can see, and 7th card face down - private, no public/community cards. (Fix limit only. and has ante)
    SevenStudHiLo = 21, // hybrid of 7 stud and Omaha hi/lo
    Razz = 25, // Form of 7 stud game but the lowest possible five-card hand is winner
    FiveCardDraw = 26, // Similar to triple draw but Hi
    MixTable = 100 // the variant is changing every hand and sent by the server.

}