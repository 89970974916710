import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DialogBase } from '@app/components/dialog/dialog-base';
import { BuyChips } from '../../models/buy-chips';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '@app/components/button/button.component';
import { GameCurrencyPipe } from '@app/pipes/game-currency.pipe';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subject, map, takeWhile, tap, timer } from 'rxjs';
import { InputComponent } from '@app/components/input/input.component';

@Component({
  selector: 'app-game-dialog-buy-chips',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonComponent,
    InputComponent,
    GameCurrencyPipe,
  ],
  templateUrl: './game-dialog-buy-chips.component.html',
  styleUrl: './game-dialog-buy-chips.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: { class: 'dialog app-game-dialog-buy-chips' }
})
export class GameDialogBuyChipsComponent extends DialogBase implements OnDestroy {

  addChipsAmount = new FormControl<number>(0, { nonNullable: true });
  timer$: Observable<number>;

  minAmount: number;
  maxAmount: number;
  private _destroy$ = new Subject<void>();

  constructor(@Inject(DIALOG_DATA) public data: BuyChips) {
    super()

    this.minAmount = +GameCurrencyPipe.prototype.transform(this.data.min, this.data.currency, true)
    this.maxAmount = +GameCurrencyPipe.prototype.transform(this.data.max, this.data.currency, true)


    this.timer$ = timer(0, 1000)
      .pipe(
        map((timer) => data.dialogTimeout! - (timer + 1)), // 0, 1, 2, 3, 4, 5, 6, 7, 8, 9
        tap((timer) => {
          if (timer <= 0) {
            this.close()
          }
        }),
        takeWhile((timer) => timer > 0)
      )
    this.addChipsAmount.setValue(+GameCurrencyPipe.prototype.transform(this.data.defaultBuy, this.data.currency, true))

    this.addChipsAmount.valueChanges.subscribe((value) => {
      if (value < 0) {
        this.addChipsAmount.setValue(0, { onlySelf: true, emitEvent: false })
      }
      if (value > this.maxAmount) {
        this.addChipsAmount.setValue(this.maxAmount, { onlySelf: true, emitEvent: false })
      }
    })
  }


  setChipsMin(): void {
    this.addChipsAmount.setValue(+GameCurrencyPipe.prototype.transform(this.data.min, this.data.currency, true))
  }

  setChipsMax(): void {
    this.addChipsAmount.setValue(+GameCurrencyPipe.prototype.transform(this.data.max, this.data.currency, true))
  }

  buy(): void {
    this.dialogRef.close(parseFloat(this.addChipsAmount.value.toFixed(this.data.currency.nbDecimalVisible)) * this.data.currency.multiplier)
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
