import { LobbyChatMessage } from '@app/features/lobby-chat/models/lobby-chat-message';
import { CurrencyInfo } from '@app/models/currency-info';
import { createAction, props } from '@ngrx/store';

export const setAll = createAction('[Lobby Chat] Set All Messages', props<{ messages: LobbyChatMessage[] }>());
export const updateOne = createAction('[Lobby Chat] Update Message', props<{ id: number, message: Partial<LobbyChatMessage> }>());
export const upsertOne = createAction('[Lobby Chat] Upsert Message', props<{ message: LobbyChatMessage }>());

export const getChatHistory = createAction('[Lobby Chat] getChatHistory', props<{ oldestMessageId?: number, numberOfMessages?: number }>());
export const sendMessage = createAction('[Lobby Chat] sendMessage', props<{ message: string }>());
