
<div class="dialog-content">

    <div class="app-game-dialog-buy-chips-content">
        <div class="app-game-dialog-buy-chips-content-left">

            <div class="app-game-dialog-buy-chips-content-title">Add Chips</div>
            <div class="app-game-dialog-buy-chips-content-available-amount">
                <span>Available</span>
                <span
                    class="app-game-dialog-buy-chips-content-available-amount-value">{{
                    data.available| gcy:
                    data.currency}}</span>
            </div>
            <div class="app-game-dialog-buy-chips-content-set-amount">
                <app-input placeholder="Password" [formControl]="addChipsAmount"
                    class="m-0 bg-dark"
                    type="number"
                    [step]="data.step| gcy: data.currency"
                    [min]="data.min| gcy: data.currency"
                    [max]="data.max| gcy: data.currency"
                    [symbol]="data.currency.symbol" />

                <div class="app input prefix"></div>
            </div>
        </div>
        <div class="app-game-dialog-buy-chips-content-right">

            <div class="app-game-dialog-buy-chips-content-timer">
                <img class="app-game-dialog-buy-chips-content-timer-img"
                    src="assets/img/icons/buy-chips-timer.svg" alt="timer icon">
                <span
                    class="app-game-dialog-buy-chips-content-timer-text">{{timer$
                    | async}}s</span>

            </div>
            <div class="app-game-dialog-buy-chips-content-min-max-buttons">
                <button appButton class="flat-dark-light"
                    (click)="setChipsMin()">Min: {{data.min| gcy:
                    data.currency}}
                </button>
                <button appButton class="flat-dark-light"
                    (click)="setChipsMax()">
                    Max:
                    {{ (data.available > data.max ? data.max : data.available) |
                    gcy: data.currency }}
                </button>
            </div>
        </div>
    </div>
    <div class="app-game-dialog-buy-chips-notice">
        <span
            *ngIf="addChipsAmount.value > 0 && addChipsAmount.value < minAmount">
            Amount smaller than the minimum
        </span>
    </div>
</div>
<div class="app-game-dialog-buy-chips-footer">
    <div class="app-game-dialog-buy-chips-footer-buttons">
        <button appButton class="success" (click)="buy()"
            [disabled]="addChipsAmount.value < minAmount">Buy</button>
        <button appButton (click)="close()">Cancel</button>
    </div>
</div>