import { createReducer, on } from '@ngrx/store';
import * as AuthActions from '@app/store/features/auth/auth.actions';
import { StateStatus } from '@app/enums/state-status';

export interface State {
    loggedIn: boolean;
    userId: number | undefined;
    forgotPassword: {
        email: string;
    },

    status: StateStatus,
    error: string | null
}

export const initialState: State = {
    loggedIn: false,
    userId: undefined,
    forgotPassword: {
        email: ''
    },
    status: StateStatus.idle,
    error: null
};

export const reducer = createReducer(
    initialState,

    on(AuthActions.onLoginWithUsernamePassword, (state) => ({ ...state, status: StateStatus.loading })),

    on(AuthActions.onOpenLoginPage, (state) => ({ ...state, status: StateStatus.idle, forgotPassword: { email: '' } })),

    on(AuthActions.onOpenForgotPasswordPage, (state) => ({ ...state, status: StateStatus.idle, forgotPassword: { email: '' } })),

    on(AuthActions.onRequestSecurityCode, (state) => ({ ...state, status: StateStatus.loading })),

    on(AuthActions.onLoginWithSecurityCode, (state) => ({ ...state, status: StateStatus.loading })),

    on(AuthActions.updateForgotPasswordEmail, (state, { email }) => ({ ...state, status: StateStatus.idle, forgotPassword: { email } })),

    on(AuthActions.loginSuccessful, (state, { userId }) => ({ ...state, status: StateStatus.idle, userId, loggedIn: true })),

    on(AuthActions.loginError, (state) => ({ ...state, status: StateStatus.idle, userId: undefined, loggedIn: false })),

    on(AuthActions.onLogout, (state) => (initialState)),

);





// export interface AuthStateUserMemberProfile {
//     id: number;
//     username: string;
//     avatar: string; // relative url of the domain game.xxx of the player avatar
//     firstName: string;
//     lastName: string;
//     email: string;
//     address: string;
//     state: string; // or Province whatever
//     zip: string; // or postal code
//     city: string;
//     country: string; // 2 letter ISO
//     phone: string;
//     phoneStatus: string; // see Phone Verification for code detail
//     status: string; // government document approval status. verified. 0: unverified, 1: pending, 2: verified
//     isVerified: boolean; // bool: email verification
//     governmentId: string; // generic string containing data related to indentification
//     ageGroup: number; // 1: 18-30, 2: 30-45, 3:45-55, 4: 55+
//     gender: string; // M, F or U, if the letter is empty or invalid, considered as unknown
//     dateJoined: Date; // date
//     dateBirth: Date; // date
//     chatBan: boolean; // bool
//     runItTwice: boolean; // bool: Option that the player agree to run the end of the hand twice in case of all in and in face to face
//     telemgramUsername: string;
//     disconnectionProtectionBan: boolean;
//     memberType: number;
//     percentage: number;
//     preferences: {
//         runItTwice: boolean;
//         autoMuck: boolean;
//         jumpToTable: boolean;
//         playerNotes: string;
//         playerColor: string;
//     },
//     profileForm: [
//         {
//             name: string;
//             isReadOnly: boolean;
//             isMandatory: boolean;
//             regex: string
//         },
//     ],
// }

// export interface AuthStateUser {
//     idPlayer: number | undefined;
//     memberProrfile: any,
//     selectedCurrency: any,
//     gifts: any;
//     playerLevelStatus: any;
// }