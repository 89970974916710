import { DOCUMENT } from '@angular/common';
import { ElementRef, Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private readonly _rendererFactory2 = inject(RendererFactory2);
  private document = inject(DOCUMENT);
  private _renderer2: Renderer2;

  private themeLoaded = new ReplaySubject<any>(1);
  constructor() {
    this._renderer2 = this._rendererFactory2.createRenderer(null, null);
  }

  applyTheme() {


    const theme = this._renderer2.createElement('link') as HTMLLinkElement;
    this._renderer2.setProperty(theme, 'rel', 'stylesheet');
    this._renderer2.setProperty(theme, 'href', 'https://data.createpoker.app/app/game/style/devryops-ltxf/modify.css');
    this._renderer2.setProperty(theme, 'id', 'theme');
    this._renderer2.setProperty(theme, 'onload', () => { console.log("CSS LOADED"); this.themeLoaded.next('aaa') });

    this._renderer2.appendChild(document.head, theme);

    const styles = [
      { name: 'body-background-color', value: "#ccc" },


    ];


    styles.forEach(data => {
     this.document.documentElement.style.setProperty(`--${data.name}`, data.value);
      
      //  this.document.documentElement.attributeStyleMap
      // document.documentElement.className; .style.setProperty(`--${data.name}`, data.value);

      //const a = this.document.getElementsByClassName('dsa').namedItem('dsa').documentElement

    });



    return this.themeLoaded;
  }
}
