export enum ErrorCode {
    NoError = 0,
    // Success, // account have been created
    CreateAccountEmailTaken = 1,
    CreateAccountUsernameTaken = 2,
    CreateAccountRecaptchaMissing = 3, // recaptch is required and not provided
    CreateAccountRecaptchaMismatch = 4, // recaptch is not matching google api
    CreateAccountReferralRequired = 5, // the referral code is required but is missing
    CreateAccountReferralInvalid = 6, // the referral code doesn't match any valid referral code

    LoginConnectionDetectedInAnotherDevice = 7,
    LoginConnectionFromAnotherDevice = 8, // disconnected because connected from another device
    LoginBlacklist = 9, // the computer is on the blacklist
    LoginUserNotFound = 10,
    LoginWrongPassword = 11,
    LoginWrongCode = 12, // when the user try to login with the forgot password reset code (Str4 parameter from Connect)
    LoginAccountLocked = 13,
    LoginRestrictedAccount = 14,
    LoginGuidAlreadyUsed = 34,
    LoginSelfExclusion = 29, // player have self exclude himself
    LoginNotAnAdmin = 26, // user tried to login to wrong applicaiton, eg player tried to login admin panel
    UpgradeRequired = 15, // the client version must be upgraded, Text: the url to re-install the game, Text2: More details about minimum version required
    SkinMismatch = 16, // the client skin do not correspond to the account skin

    ChangePasswordWrongOldPassword = 17,
    BlockedCountry = 18, // this country is not allowed to play the game
    BlockedAccountCreated = 19, // too many accounts created from same IP

    BuyChipsUnderMinimumBuyIn = 20,
    BuyChipsOverMaximumBuyIn = 21,
    BuyChipsInsuficientFunds = 22,
    BuyChipsTournamentStarted = 23,
    BuyChipsWhilePlaying = 24,
    HitAndRun = 25, // value1 = requested amount

    TakeSeatNotAvailable = 30,
    TakeSeatAlreadyTaken = 31,
    TakeSeatInvalidPosition = 32, // on a 4 player table the valid seat are 0 to 3, cannot take seat 4 to 9.
    JoinTableRejectedAlreadySit = 33, // cannot join table because already sit down, PlayNow mode error only
    NotConnected = 40,

    TipRejected = 41, // the tip was rejected because it's not the right amount

    ChatRejected = 50,

    TableIsStopped = 59, // the table is stopped, nobody can sit down
    TableDoesntExist = 60, // MTT table dissolve // still in used?
    TournamentDoNotExist = 61, // MTT
    TournamentRegistrationClose = 62, // try to register when tournament is started or registration is closed
    TournamentRegistrationFull = 63, // try to register when tournament is already at max player
    TournamentRegistrationNotEnoughFund = 64, // try to register but not enough money
    TournamentRegistrationRequireTicket = 161, // the tournament can only be register with a ticket
    TournamentRegistrationPrivate = 65, // this tournament is restricted to a list of player
    TournamentRegistrationRequestDeposit = 66, // the player must have complete a deposit before be able to play
    TournamentRegistrationRequireRakeback = 160, // the player must have pay some rakeback in the recent days to participate
    TournamentRegistrationNotStartedYet = 67, // try to register but too early
    TournamentRegistrationCannotUnregister = 162, // special option that some tournament cannot be unregister
    SameComputer = 68, // same computer try to join the same table on different account
    CollusionRestriction = 69, // the server do not allowed those individual to play together

    PasswordMismatch = 70,

    // tournament rebuy error
    RebuyRejectedTooManyChips = 71,
    RebuyRejectedMaxLimitAllowed = 72,
    RebuyRejectedTimeLimit = 73,
    RebuyRejectedCannotWhilePlaying = 74,
    AddOnRejectedAlreadyTookThisBreak = 75,
    //ReEntryRejected = 76, // the player could not re-enter the tournament (limit or registration closed)

    TransferP2PDisabled = 80,
    TransferP2PLimitExceeding = 81,

    GiftError = 100, // id gift not found, or someone else, or already refund, check description

    TdsWithdrawalRejectedBecauseMoneyInPlay = 110, // TDS withdrawal rejected because it some money was in play
    EmailError = 200,
    NotFound = 201, // sort of generic 
    UsernameRejected = 202,
    UsernameTooShort = 203,
    UsernameTooLong = 204,
    PasswordTooShort = 205,
    AccountNotVerfied = 206, // this operation required the user to be "verified" status
    PhoneNumberRequired = 207,
    PhoneNumberAlreadyInUse = 208, // someone else have this phone number

    // gateway error
    DepositRequestAmountTooSmall = 300,
    DepositRequestAmountTooBig = 303,
    WithdrawalInProgress = 301, // cannot do a withdrawal because there is already one in common.
    DepositRequestRestricted = 302, // cannot deposit because the player have enable deposit restriction and player doesn't have "CanBuyChip" flag.
    WithdrawRequestAmountTooSmall = 310,
    WithdrawRequestAmountTooBig = 311,

    HandNumberNotDefined = 400, // the hand number is not send, should be in "Value" parameter
    HandNumberNotFound = 401, // the hand number is not found on the server, too old? not yet executed?
    HandNumberNotEnded = 402, // hand is still in progress
    HandNumberAccessDenied = 403, // you don't have the permission to view this hand, maybe you didn't participate in the hand?


    // mixed table
    TableVariantIsNotMixedTable = 500,

    // Emoticons
    EmotikensBuyPackage = 601,
    EmoticonUnlock = 602,

    CurrencyBuyPackage = 610,
    BuyTicketNoTicket = 611,
    BuyTicketNoMoney = 612,
    BuyTicketExeededMaxTicketsPerPlayer = 613,
    BuyTicketExeededMaxTicketsPerAllPlayers = 614,
    BuyTicketExeededMaxUnusedTicketsPerPlayer = 615,

    // Rabbit Hunting
    RabbitHuntingCharging = 620,

    // CallTIme
    CallTimeIsDisabled = 650,

    // generic error message
    InsuficientFunds = 1001,
    InvalidParameters = 1002,


    BitcoinError = 8000, // error related to bitcoin implementation

    Unknown = 9999
}