import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import * as TablesActions from './tables.actions';


import * as AuthActions from '@app/store/features/auth/auth.actions';
import { TableInfo } from '@app/models/table-info';
import { MixTableDetails } from '@app/models/mix-table-details';
import { TableSummary } from '@app/models/table-summary';

export interface Table {
    id: number;
    tableSummary?: TableSummary;
    tableInfo?: TableInfo;
    mixTableDetails?: MixTableDetails;
}

export interface State extends EntityState<Table> {

}


export const adapter: EntityAdapter<Table> = createEntityAdapter<Table>({
    selectId: (model: Table) => model.id,
    sortComparer: false,
});

export const initialState: State = adapter.getInitialState({

});


export const reducer = createReducer(initialState,
    on(TablesActions.upsertOne, (state, { table }) => {
        return adapter.upsertOne({ ...table }, { ...state });
    }),

    on(TablesActions.removeOne, (state, { tableId }) => {
        return adapter.removeOne(tableId, { ...state });
    }),

    on(TablesActions.updateOne, (state, { tableId, table }) => {
        return adapter.updateOne({ id: tableId, changes: { ...table } }, { ...state });
    }),

    on(AuthActions.onLogout, (state) => {
        return adapter.removeAll({ ...state });
    }),
);
