import { ActionReducerMap } from "@ngrx/store";
import * as Lobby from '@app/store/features/lobby/lobby.reducer';
import * as TableSummaries from '@app/store/features/table-summaries/table-summaries.reducer';
import * as SpinNGoSummaries from '@app/store/features/spin-n-go-summaries/spin-n-go-summaries.reducer';
import * as SitNGoSummaries from '@app/store/features/sit-n-go-summaries/sit-n-go-summaries.reducer';
import * as TournamentSummaries from '@app/store/features/tournament-summaries/tournament-summaries.reducer';
import * as Tables from '@app/store/features/tables/tables.reducer';
import * as Tournaments from '@app/store/features/tournaments/tournaments.reducer';
import * as User from '@app/store/features/user/user.reducer';
import * as Currencies from '@app/store/features/currencies/currencies.reducer';
import * as PlayerBalance from '@app/store/features/player-balance/player-balance.reducer';
import * as LobbyChat from '@app/store/features/lobby-chat/lobby-chat.reducer';
import * as Tickets from '@app/store/features/tickets/tickets.reducer';
import * as PlayerLevelStatus from '@app/store/features/player-level-status/player-level-status.reducer';
import * as Gifts from '@app/store/features/gifts/gifts.reducer';
import * as RAFBonuses from '@app/store/features/raf-bonuses/raf-bonuses.reducer';
import * as Cashier from '@app/store/features/cashier/cashier.reducer';
import * as Games from '@app/store/features/games/games.reducer';

export interface State {
    tableSummaries: TableSummaries.State;
    spinNGoSummaries: SpinNGoSummaries.State;
    sitNGoSummaries: SitNGoSummaries.State;
    tournamentSummaries: TournamentSummaries.State;
    lobby: Lobby.State;
    tables: Tables.State;
    tournaments: Tournaments.State;
    user: User.State;
    currencies: Currencies.State;
    playerBalance: PlayerBalance.State;
    lobbyChat: LobbyChat.State;
    tickets: Tickets.State;
    playerLevelStatus: PlayerLevelStatus.State;
    gifts: Gifts.State;
    rafBonuses: RAFBonuses.State;
    cashier: Cashier.State;
    games: Games.State;
}


export const reducers: ActionReducerMap<State> = {
    tableSummaries: TableSummaries.reducer,
    spinNGoSummaries: SpinNGoSummaries.reducer,
    sitNGoSummaries: SitNGoSummaries.reducer,
    tournamentSummaries: TournamentSummaries.reducer,
    lobby: Lobby.reducer,
    tables: Tables.reducer,
    tournaments: Tournaments.reducer,
    user: User.reducer,
    currencies: Currencies.reducer,
    playerBalance: PlayerBalance.reducer,
    lobbyChat: LobbyChat.reducer,
    tickets: Tickets.reducer,
    playerLevelStatus: PlayerLevelStatus.reducer,
    gifts: Gifts.reducer,
    rafBonuses: RAFBonuses.reducer,
    cashier: Cashier.reducer,
    games: Games.reducer

}
