
import { Injectable } from '@angular/core';
import { filter, map, tap } from 'rxjs/operators';
import { ServerResponse } from '@app/enums/server-response';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { LobbyService } from '../../../features/lobby/services/lobby.service';

import * as LobbyActions from '../lobby/lobby.actions';
import * as SpinNGoSummariesActions from './spin-n-go-summaries.actions';
import { WsService } from '@app/services/ws.service';
import { SpinNGoSummary, SpinNGoSummaryResponse } from '@app/models/spin-n-go-summary';

@Injectable()
export class SpinNGoSummariesEffects {
    constructor(
        private _ws: WsService,
        private readonly store: Store,
        private readonly lobbyService: LobbyService,
        private actions$: Actions,

    ) { }

    // updateActiveNav$ = createEffect(
    //     () =>
    //         this.actions$.pipe(
    //             ofType(LobbyActions.updateActiveNav),
    //             filter(({ activeNav }) => activeNav === LobbyNav.SpinNGo),
    //             tap(() => this.lobbyService.getSpinNGoSummaries()),
    //             map(() => SpinNGoSummariesActions.updateLoader({ loader: true }))
    //         ));


    updateSpinNGoSummaries$ = createEffect(() =>
        this._ws.getDataResponse<SpinNGoSummaryResponse[]>(ServerResponse.SpinNGoSummaries)
            .pipe(
                tap(d=>console.log('SpinNGoSummariesEffects updateSpinNGoSummaries$ d',d)),
                map(spinNGoSummariesResponse => {
                    const spinNGoSummaries: SpinNGoSummary[] = spinNGoSummariesResponse.map(spinNGoSummaryResponse => {
                        return this.lobbyService.updateSpinNGoSummary(spinNGoSummaryResponse)
                    })

                    return SpinNGoSummariesActions.updateAll({ spinNGoSummaries })
                })
            ));

} 