import { State, createFeatureSelector, createSelector } from "@ngrx/store";
import * as mainReducers from '@app/store/features/main/main.reducers';
import * as TablesReducer from './tables.reducer';

export const selectMainState = createFeatureSelector<mainReducers.State>('main');
export const selectTableState = createSelector(selectMainState, (state) => state.tables);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = TablesReducer.adapter.getSelectors(selectTableState);

export const selectEntityById = (id: number) => createSelector(
    selectEntities,
    (entities) => entities[id]
);