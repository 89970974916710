import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import * as CurrenciessActions from './currencies.actions';
import { CurrencyInfo } from '@app/models/currency-info';

export interface State extends EntityState<CurrencyInfo> {
    selected?: CurrencyInfo;

}

export const adapter: EntityAdapter<CurrencyInfo> = createEntityAdapter<CurrencyInfo>({
    selectId: (model: CurrencyInfo) => model.id,
});

export const initialState: State = adapter.getInitialState({

});

export const reducer = createReducer(initialState,
    on(CurrenciessActions.setAll, (state, { currencies }) => {
        return adapter.setAll(currencies, { ...state, selected: currencies[0] });
    }),

    on(CurrenciessActions.selectCurrency, (state, { selected }) => {
        return { ...state, selected };
    }),
);
