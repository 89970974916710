import { Injectable } from '@angular/core';
import { CurrencyInfo, CurrencyInfoDTO } from '@app/models/currency-info';
import { GlobalSettings } from '@app/models/global-settings';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor() { }

  updateCurrencyInfo(currencyInfoDTO: CurrencyInfoDTO, globalSettings: GlobalSettings): CurrencyInfo {
    const currencyInfo: CurrencyInfo = {
      ...currencyInfoDTO,
      symbol: currencyInfoDTO.symbol ?? '',
      multiplier: currencyInfoDTO.multiplier ?? 1,

      rafEnabled: globalSettings.rafEnabledData.find(rafEnabled => rafEnabled.currency === currencyInfoDTO.id)?.enabled ?? false,
      fdbEnabled: globalSettings.fdbEnabledData.find(fdbEnabled => fdbEnabled.currency === currencyInfoDTO.id)?.enabled ?? false,
    }
    return currencyInfo
  }
}
