import { createReducer, on } from '@ngrx/store';
import * as WsActions from '@app/store/features/web-socket/web-socket.actions';
import { ConnectionStatus } from '@app/enums/connection-status';


export interface State {
    status: ConnectionStatus;
    ping: number;
    pong: number;
    latency: number;
}

export const initialState: State = {
    status: ConnectionStatus.Disconnected,
    ping: 0,
    pong: 0,
    latency: 0
};

export const reducer = createReducer(
    initialState,

    on(WsActions.updateConnectionStatus, (state, { status }) => ({ ...state, status, ping: 0, pong: 0, latency: 0 })),
    on(WsActions.updatePing, (state) => ({ ...state, ping: Date.now() })),
    on(WsActions.updatePong, (state) => {
        const pong = Date.now();
        return {
            ...state,
            pong,
            latency: state.ping > 0 ? pong - state.ping : 0
        }
    }),
);