import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import * as TournamentSummariesActions from './tournament-summaries.actions';
import * as AuthActions from '@app/store/features/auth/auth.actions';
import { TournamentSummary } from '@app/models/tournament-summary';

export interface State extends EntityState<TournamentSummary> {
    loader: boolean;
}

export const adapter: EntityAdapter<TournamentSummary> = createEntityAdapter<TournamentSummary>({
    selectId: (model: TournamentSummary) => model.id,
    sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
    loader: false,
});


function schedulerSort(propA: TournamentSummary, propB: TournamentSummary): number {
    console.log(propA.game.startInDate.getTime() - propB.game.startInDate.getTime())
    return propA.game.startInDate.getTime() - propB.game.startInDate.getTime();
}

export const reducer = createReducer(initialState,
    on(TournamentSummariesActions.updateLoader, (state, { loader }) => {
        return { ...state, loader };
    }),

    on(TournamentSummariesActions.updateAll, (state, { tournamentSummaries }) => {
        return adapter.setAll(tournamentSummaries, { ...state, loader: false });
    }),

    on(TournamentSummariesActions.updateOne, (state, { id, tournamentSummary }) => {
        return adapter.updateOne({ id, changes: { ...tournamentSummary } }, { ...state });
    }),

    on(AuthActions.onLogout, (state) => {
        return adapter.removeAll({ ...state });
    }),

    // on(TournamentSummariesActions.updateTournamentCountDown, (state) => {
    //     return adapter.updateMany({ id: [], changes: { game.scheduleDemo = 123 } });
    // }),
);
