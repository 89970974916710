import { createReducer, on } from '@ngrx/store';
import * as UserActions from '@app/store/features/user/user.actions';
import { SkinSettings } from '@app/models/skin-settings';
import { GlobalSettings } from '@app/models/global-settings';
import { MemberProfile } from '@app/models/member-profile';
import { CurrencyInfo } from '@app/models/currency-info';
import { PlayerBalance } from '@app/models/player-balance';



export interface State {
    profile: MemberProfile | null;

    checkEmailExist?: {
        email: string;
        exist: boolean;
    },
    checkUsernameExist?: {
        username: string;
        exist: boolean;
    }

}

export const initialState: State = {
    profile: null,
};

export const reducer = createReducer(
    initialState,
    on(UserActions.updateProfile, (state, { profile }) => ({ ...state, profile })),
    on(UserActions.updateEmailExist, (state, { email, exist }) => ({ ...state, checkEmailExist: { email, exist } })),
    on(UserActions.updateUsernameExist, (state, { username, exist }) => ({ ...state, checkUsernameExist: { username, exist } })),



);