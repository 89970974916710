import { State, createFeatureSelector, createSelector } from "@ngrx/store";
import * as TableSummariesReducer from './table-summaries.reducer';
import * as mainReducers from '@app/store/features/main/main.reducers';

export const selectMainState = createFeatureSelector<mainReducers.State>('main');
export const selectTableSummariesState = createSelector(selectMainState, (state) => state.tableSummaries);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = TableSummariesReducer.adapter.getSelectors(selectTableSummariesState);

export const selectLoader = createSelector(
    selectTableSummariesState,
    (state) => state?.loader
);

export const selectEntityById = (id: number) => createSelector(
    selectEntities,
    (entities) => entities[id]
);
