export enum TransactionType {
    Deposit = 1, // make a deposit from outside payment system
    Withdraw = 2, // make a withdraw to outside payment system
    WithdrawCanceled = 4, // cancel of a withdrawal
    Transfer = 3, // transfer between player
    TransferFrom = 5, // transfer from a player, this type only exist in api, it's the same as Transfer with negative value
    GiftSignupBonus = 60,  // bonus when player make first deposit

    RabbitHunting = 39,  // RabbitHunting
    GiftDepositBonus = 61,
    GiftReferAFriendRegister = 62, // the player who join that get a bonus
    GiftRakeBackBonus = 63, // bonus as rakeback
    GiftReferAFriendReferrer = 64,  // the player who invited that get a bonus
    GiftOther = 69,
    IncomePayout = 70, // when the income money is transfer into cash available
    Rakeback = 71, // periodically returning the rake to the player,
}

export enum TransactionStatus {
    Confirmed = 1,
    Pending = 2,
    Canceled = 3
}
export interface TransactionDTO {
    // Date [DataTime] the date of the transaction
    // Type [int/enum] see bellow for possible value
    // Amount [Int64] the amount of the transaction, this is raw int64 format and need formatting
    // Currency [int/enum] the currency of the transaction
    // Status [int/enum] the status of the transaction (1 = confirmed, 2 = pending, 3 = canceled)
    // ExtraInfo [String] extra information to display such as transaction number or name of the player when transferring money.

    date: string;
    type: TransactionType;
    amount: number;
    currency: number;
    status: TransactionStatus;
    extraInfo: string;
    deposit: boolean;
}

export interface Transaction extends TransactionDTO {
    dateFormatted: Date;
    typeFormatted: string;
}