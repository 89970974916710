import { Assets, Graphics, TilingSprite, Container, Texture, Sprite, Loader, TextStyle, ITextStyle } from 'pixi.js';
import { Point } from '../models/point';
import { GlowFilter } from '@pixi/filter-glow';

export class PlayerTimebankProgressBar {
    container = new Container();

    private containerSize: Point = { x: 180, y: 4 };
    private barSize = { width: 252, height: 4 };
    private barDefaultColor = 0xdf0226;
    private barTimeBankColor = 0x0231df;

    private barBackgroundColor = 0xFFFFFF;
    private barCurrentColor = 0xFFFFFF;

    private bar: Graphics;
    private barBackground: Graphics;

    glowFilter: GlowFilter;


    constructor(position: Point) {
        this.container.position.set(position.x, position.y);

        this.glowFilter = new GlowFilter({
            distance: 12,
            outerStrength: 2,
            innerStrength: 2,
            color: this.barCurrentColor
        });

        this.barBackground = new Graphics();
        this.barBackground.beginFill(this.barBackgroundColor);
        this.barBackground.drawRoundedRect(0, 0, this.barSize.width, this.barSize.height, 100);
        this.barBackground.endFill();
        this.barBackground.alpha = 0.2;
        this.container.addChild(this.barBackground);

        this.bar = new Graphics();
        this.bar.beginFill(this.barCurrentColor);
        this.bar.drawRect(0, 0, this.barSize.width, this.barSize.height);
        this.bar.endFill();
        this.bar.filters = [this.glowFilter];
        this.container.addChild(this.bar);
    }

     update(newScale: number): void {
        this.bar.width = this.barSize.width * newScale;
        this.bar.tint = this.barCurrentColor;
        this.glowFilter.color = this.barCurrentColor;
    }

     reset() {
        this.bar.tint = this.barCurrentColor;
        this.glowFilter.color = this.barCurrentColor;

        this.bar.clear();
        this.bar.beginFill(this.barCurrentColor);
        this.bar.drawRect(0, 0, this.barSize.width, this.barSize.height);
        this.bar.endFill();
    }

     changeToTimebankColor() {
        this.barCurrentColor = this.barTimeBankColor;
    }

     changeToDefaultColor() {
        this.barCurrentColor = this.barDefaultColor;
    }


 

}
