import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import * as AuthActions from '@app/store/features/auth/auth.actions';
import * as PlayerBalanceActions from '@app/store/features/player-balance/player-balance.actions';

import { PlayerBalance } from '@app/models/player-balance';

export interface State extends EntityState<PlayerBalance> {

}

export const adapter: EntityAdapter<PlayerBalance> = createEntityAdapter<PlayerBalance>({
    selectId: (model: PlayerBalance) => model.currency,
});

export const initialState: State = adapter.getInitialState({

});

export const reducer = createReducer(initialState,
    on(PlayerBalanceActions.upsertOne, (state, { playerBalance }) => {
        return adapter.upsertOne({ ...playerBalance }, { ...state });
    }),

    on(AuthActions.onLogout, (state) => {
        return adapter.removeAll({ ...state });
    }),

);
