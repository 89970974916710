import { Injectable, inject } from '@angular/core';
import { ServerRequestMessage } from '@app/enums/server-request';
import { CasinoInfo, CasinoInfoDTO } from '@app/models/casino-info';
import { WsService } from './ws.service';

@Injectable({
  providedIn: 'root'
})
export class CasinoService {
  private readonly _ws = inject(WsService)

  constructor() { }

  updateCasino(casinoInfoDTO: CasinoInfoDTO): CasinoInfo {
    return {
      ...casinoInfoDTO,
      config: {
        token: casinoInfoDTO.parameters.token === '##',
        buyInAmount: casinoInfoDTO.parameters.buyInAmount === '##',
        username: casinoInfoDTO.parameters.username === '##',
      }
    }
  }




  getCasinoToken(casinoProduct: string) {
    this._ws.sendData({
      type: ServerRequestMessage.GetAuthToken,
      str1: casinoProduct,
    });
  }
}
