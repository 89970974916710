
import { Injectable } from '@angular/core';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ServerResponse } from '@app/enums/server-response';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { LobbyService } from '../../../features/lobby/services/lobby.service';

import * as LobbyActions from '../lobby/lobby.actions';
import * as TournamentSummariesActions from '../tournament-summaries/tournament-summaries.actions';
import { ServerMessageType } from '@app/enums/server-message-type';

import { Store, select } from '@ngrx/store';

import * as TablesSelectors from '../tables/tables.selector';

import * as TournamenstSelectors from './tournaments.selector';

import * as TournamenstActions from './tournaments.actions';
import { WsService } from '@app/services/ws.service';
import { TournamentSummaryResponse } from '@app/models/tournament-summary';
import { TournamentInfo, TournamentInformationResponse } from '@app/models/tournament-information';
import { BlindInformation } from '@app/models/blind-information';
import { pl } from 'date-fns/locale';
import { TournamentActiveTable } from '@app/models/tournament-active-table';
import { combineLatest } from 'rxjs';
import { TournamentInfoPlayer } from '@app/models/tournament-info-player';
import { cloneDeep } from 'lodash';

import * as UserSelectors from '@app/store/features/user/user.selectors';
import * as CurrenciesSelectors from '@app/store/features/currencies/currencies.selectors';
import * as PlayerBalanceSelectors from '@app/store/features/player-balance/player-balance.selectors';
import { ServerMsgConnectResponse, ServerMsgTournamentError } from '@app/models/server-msg';
import { ErrorCode } from '@app/enums/errors';

@Injectable()
export class TournamentsEffects {
    constructor(
        private _ws: WsService,
        private readonly _store: Store,
        private readonly lobbyService: LobbyService,
        private actions$: Actions,

    ) { }


    tournamentError$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgTournamentError>(ServerMessageType.Error)
            .pipe(
                filter(error => [ErrorCode.TournamentDoNotExist].indexOf(error.errorCode) > -1),
                map(error => TournamenstActions.upsertOne({ tournament: { id: error.idTournament, errorCode: error.errorCode } }))
            ));


    onSelectTournament$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LobbyActions.onSelectTournament),
                tap(({ tournamentSummary }) => {
                    this.lobbyService.getTournamentSummary(tournamentSummary.id);
                    this.lobbyService.getTournamentInfo(tournamentSummary.id);
                    this.lobbyService.getBlindSchedule(tournamentSummary.id);
                }),
                map(({ tournamentSummary }) => TournamenstActions.upsertOne({ tournament: { id: tournamentSummary.id, tournamentSummary } }))
            ));

    onSelectSitNGo$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LobbyActions.onSelectSitNGo),
                tap(({ sitNGoSummary }) => {
                    this.lobbyService.getTournamentSummary(sitNGoSummary.id);
                    this.lobbyService.getTournamentInfo(sitNGoSummary.id);
                    this.lobbyService.getBlindSchedule(sitNGoSummary.id);
                }),
                map(({ sitNGoSummary }) => TournamenstActions.upsertOne({ tournament: { id: sitNGoSummary.id, sitNGoSummary } }))
            ));

    onSelectSpinNGo$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LobbyActions.onSelectSpinNGo),
                tap(({ spinNGoSummary }) => {
                    this.lobbyService.getTournamentSummary(spinNGoSummary.id);
                    this.lobbyService.getTournamentInfo(spinNGoSummary.id);
                    this.lobbyService.getBlindSchedule(spinNGoSummary.id);
                }),
                map(({ spinNGoSummary }) => TournamenstActions.upsertOne({ tournament: { id: spinNGoSummary.id, spinNGoSummary } }))
            ));

    updateTournamentSummary$ = createEffect(() =>
        this._ws.getDataResponse<TournamentSummaryResponse>(ServerResponse.TournamentSummary)
            .pipe(
                map(tournamentSummaryResponse => {
                    const tournamentSummary = this.lobbyService.updateTournamentSummary(tournamentSummaryResponse)
                    return TournamenstActions.upsertOne({ tournament: { id: tournamentSummaryResponse.id, tournamentSummary } })
                }))
    );

    updateTournamentInformationResponse$ = createEffect(() =>
        this._ws.getDataResponse<TournamentInformationResponse>(ServerResponse.TournamentInformation)
            .pipe(
                switchMap((tournamentInformationResponse) => {
                    return combineLatest([
                        this._store.pipe(select(UserSelectors.selectUserProfile)),
                        this._store.pipe(select(TournamenstSelectors.selectEntityById(tournamentInformationResponse.idTournament)))
                    ]).pipe(
                        take(1),
                        map(([userProfile, tournament]) => {

                            /**
                             * @version 0.0.1
                             * move to adapter service
                             */

                            let prizesColumns: string[] = tournament?.tournamentInfo?.game.prizesColumns ?? [];
                            let prizes: any = tournament?.tournamentInfo?.game.prizes ?? [];
                            if (tournamentInformationResponse.prizes) {
                                prizes = tournamentInformationResponse.prizes.sort((a, b) => a.position - b.position > 0 ? 1 : -1);
                            }
                            if (tournamentInformationResponse?.prizes2) {
                                prizes = tournamentInformationResponse.prizes2.sort((a, b) => a.position - b.position > 0 ? 1 : -1);

                                const columns: any = {};

                                for (const item of prizes) {
                                    if (item.position) {
                                        columns['Position'] = true;
                                    }
                                    if (item.chips && item.chips.percentage) {
                                        columns['ChipsPercentage'] = true;
                                    }
                                    if (item.chips && item.chips.amount) {
                                        columns['ChipsAmount'] = true;
                                    }
                                    if (item.tickets) {
                                        columns['Tickets'] = true;
                                    }
                                    if (item.externalTickets) {
                                        columns['ExternalTickets'] = true;
                                    }
                                    if (item.externalPoints) {
                                        columns['Points'] = true;
                                    }
                                }
                                prizesColumns = Object.keys(columns);

                            }

                            let highestStack = 0
                            let lowestStack = 0
                            let averageStack = 0

                            if (tournamentInformationResponse.players.length > 0) {
                                const numberOfChips = tournamentInformationResponse.players
                                    .filter(player => player.nbChips > 0)
                                    .map(player => player.nbChips);

                                highestStack = Math.max(...numberOfChips);
                                lowestStack = Math.min(...numberOfChips);
                                averageStack = Math.round(numberOfChips.reduce((total, num) => total + num, 0)) / numberOfChips.length
                            }



                            // #######  Players START

                            let tournamentPlayers: TournamentInfoPlayer[] = tournament?.tournamentInfo?.players ? cloneDeep(tournament?.tournamentInfo?.players) : [];

                            if (tournamentInformationResponse.nbPlayerRegistered === tournamentInformationResponse.players.length) {
                                tournamentPlayers = tournamentInformationResponse.players.map(player => {
                                    player.nbRebuy = player.nbRebuy ?? 0;
                                    player.bounty = player.bounty ?? 0;
                                    player.bountyEarned = player.bountyEarned ?? 0;
                                    player.nbChips = player.nbChips ?? 0;

                                    return player
                                })
                                // sometimes server doesn't send Prizes so check is needed
                                // from doc: "If this property is not present it's because the previous one didn't change."

                            } else {
                                // check for new players to be added to the list
                                for (const player of tournamentInformationResponse.players) {
                                    const existingPlayerIndex = tournamentPlayers.findIndex(oldPlayer => oldPlayer.idPlayer === player.idPlayer);
                                    if (existingPlayerIndex > -1) {
                                        tournamentPlayers[existingPlayerIndex].idPlayer = player.idPlayer;
                                        tournamentPlayers[existingPlayerIndex].nbChips = player.nbChips ?? 0;
                                        tournamentPlayers[existingPlayerIndex].idTable = player.idTable;
                                        tournamentPlayers[existingPlayerIndex].bounty = player.bounty;
                                        tournamentPlayers[existingPlayerIndex].bountyEarned = player.bountyEarned ?? 0;
                                        tournamentPlayers[existingPlayerIndex].positionEnded = player.positionEnded;

                                    } else {
                                        tournamentPlayers.push(player);
                                    }
                                }
                            }



                            // ###### Players END
                            const myPlayer = tournamentInformationResponse.players.find(player => player.idPlayer === userProfile?.id);
                            const imOnTour = !myPlayer?.positionEnded ? myPlayer : undefined;
                            const iWasOnTour = myPlayer?.positionEnded ? myPlayer : undefined;
                            const isRegistered = !!(imOnTour && !imOnTour.positionEnded);


                            // #######  active tables START

                            let activeTables: TournamentActiveTable[] = tournament?.activeTables ? cloneDeep(tournament?.activeTables) : [];

                            if (tournamentInformationResponse.nbPlayerRegistered === tournamentInformationResponse.players.length) {
                                activeTables = [];
                            }

                            // update tables and players
                            for (const player of tournamentInformationResponse.players) {
                                // if player belongs to one of the existing tables
                                const tableExist = activeTables.find(table => table.idTable === player.idTable);


                                if (!tableExist) {
                                    if (!player.positionEnded && player.idTable !== 0) { // => Player at active table have TableId diffrent than 0, inactive tables have ids 0
                                        activeTables.push(<TournamentActiveTable>{
                                            idTable: player.idTable,
                                            minChips: player.nbChips,
                                            maxChips: player.nbChips,
                                            playersList: [player],
                                            numberOfPlayers: 1,
                                            isMyTable: player.idPlayer === userProfile!.id
                                        });
                                    }
                                } else {
                                    // check if player is already assigned to table
                                    const existingPlayerIndex = tableExist.playersList.findIndex(p => p.idPlayer === player.idPlayer);

                                    // if player doesn't already exist in table list add the player to players list
                                    if (existingPlayerIndex === -1) {

                                        tableExist.playersList.push(player);

                                    } else {


                                        tableExist.playersList[existingPlayerIndex] = player;

                                    }

                                    // remove player if he is eliminated
                                    if (player.positionEnded) {

                                        tableExist.playersList.splice(existingPlayerIndex, 1);

                                    }
                                }

                                // Remove inactive tables
                                if (tableExist && tableExist.playersList.length === 0) {
                                    const tableIndex = activeTables.findIndex(table => table.idTable === tableExist.idTable);
                                    activeTables.splice(tableIndex, 1);
                                }
                            }

                            // recalculate min/max chips and numb of players
                            for (const table of activeTables) {
                                const tableChips = [];
                                let isMyTable = false;
                                for (const player of table.playersList) {
                                    tableChips.push(player.nbChips);

                                    if (player.idPlayer === userProfile!.id) {
                                        isMyTable = true;
                                    }
                                }
                                table.playersList = table.playersList.sort((a, b) => a.nbChips - b.nbChips > 0 ? -1 : 1)

                                table.maxChips = Math.max(...tableChips) ?? 0;
                                table.minChips = Math.min(...tableChips) ?? 0;
                                table.numberOfPlayers = table.playersList.length;
                                table.isMyTable = isMyTable;
                            }
                            activeTables = activeTables.sort((a, b) => a.idTable - b.idTable > 0 ? -1 : 1);
                            // #######  active tables END






                            let tournamentInfo: TournamentInfo = {
                                ...tournamentInformationResponse,

                                game: {
                                    firstPrize: prizes[0],
                                    prizesColumns,
                                    prizes,
                                    stack: {
                                        highest: highestStack,
                                        lowest: lowestStack,
                                        average: averageStack
                                    }

                                },
                                players: tournamentPlayers.sort((a, b) => a.positionEnded - b.positionEnded > 0 ? 1 : -1)
                            }







                            return TournamenstActions.upsertOne({ tournament: { id: tournamentInformationResponse.idTournament, tournamentInfo, activeTables, isRegistered } })
                        })
                    )
                })
            )
    );

    updateBlindInformation$ = createEffect(() =>
        this._ws.getDataResponse<BlindInformation>(ServerResponse.BlindInformation)
            .pipe(
                map(blindInformation => {
                    return TournamenstActions.upsertOne({ tournament: { id: blindInformation.idTournament, blinds: blindInformation.blinds } })
                }))
    );



    //

    onViewTournament$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TournamenstActions.viewTournament),
                tap(({ tournamentId }) => {
                    this.lobbyService.getTournamentSummary(tournamentId);
                    this.lobbyService.getTournamentInfo(tournamentId);
                    this.lobbyService.getBlindSchedule(tournamentId);
                }),
            ), { dispatch: false });



    //

    onRegisterTournament$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TournamenstActions.registerTournament),
                tap(({ tournamentId, register }) => this.lobbyService.tournamentRegister(tournamentId, register)),
            ), { dispatch: false });

}