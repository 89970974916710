import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import * as AuthActions from '@app/store/features/auth/auth.actions';
import * as PlayerLevelStatusActions from '@app/store/features/player-level-status/player-level-status.actions';

import { PlayerLevelStatus } from '@app/models/player-level-status';

export interface State extends EntityState<PlayerLevelStatus> {

}

export const adapter: EntityAdapter<PlayerLevelStatus> = createEntityAdapter<PlayerLevelStatus>({
    selectId: (model: PlayerLevelStatus) => model.currency,
});

export const initialState: State = adapter.getInitialState({

});

export const reducer = createReducer(initialState,
    on(PlayerLevelStatusActions.upsertOne, (state, { playerLevelStatus }) => {
        return adapter.upsertOne({ ...playerLevelStatus }, { ...state });
    }),

    on(AuthActions.onLogout, (state) => {
        return adapter.removeAll({ ...state });
    }),

);
