import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import * as SitAndGoSummaryActions from './sit-n-go-summaries.actions';
import * as AuthActions from '@app/store/features/auth/auth.actions';
import { SitNGoSummary } from '@app/models/sit-n-go-summary';


export interface State extends EntityState<SitNGoSummary> {
    loader: boolean;
}

export const adapter: EntityAdapter<SitNGoSummary> = createEntityAdapter<SitNGoSummary>({
    selectId: (model: SitNGoSummary) => model.id,
    sortComparer: playersSort,
});

export const initialState: State = adapter.getInitialState({
    loader: false,
});

function playersSort(propA: SitNGoSummary, propB: SitNGoSummary): number {
    return propA.nbPlayerRegistered > propB.nbPlayerRegistered ? -1 : 1;
}

export const reducer = createReducer(initialState,
    on(SitAndGoSummaryActions.updateLoader, (state, { loader }) => {
        return { ...state, loader };
    }),

    on(SitAndGoSummaryActions.updateAll, (state, { sitNGoSummaries }) => {
        return adapter.setAll(sitNGoSummaries, { ...state, loader: false });
    }),

    on(SitAndGoSummaryActions.updateOne, (state, { sitNGoSummary }) => {
        return adapter.updateOne({ id: sitNGoSummary.id, changes: { ...sitNGoSummary } }, { ...state });
    }),

    on(AuthActions.onLogout, (state) => {
        return adapter.removeAll({ ...state });
    }),
);
